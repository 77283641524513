.color-picker {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .color-picker label {
    font-size: 14px;
    color: #333;
  }
  
  .color-picker input[type="color"] {
    width: 24px;
    height: 24px;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  .color-picker-wrapper {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 36px;
    margin-right: 8px; /* Adjust spacing as needed */
    vertical-align: middle; /* Align with other toolbar items */
  }
  
  .color-picker-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 2; /* Ensure the input is on top */
  }
  
  .color-picker-icon {
    font-size: 24px;
    cursor: pointer;
    z-index: 1; /* Icon below the input */
    line-height: 36px;
    text-align: center;
  }