/* parent has an specific height */

.templateParent {
    position: relative;
    margin: 0 auto;
	display: flex;
    max-width:300px;
	flex-direction: column;
	min-height: 200px;
    border:1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    flex-wrap:nowrap;
    color: #000;
    .templateParent{
        position:relative;
        max-width: 300px;
        min-height: 200px;
        border: none;
        background-color: none;

    }
}


/* set aut margin for last element */
.whatsapp-footer-buttons {
	margin-top: auto;
}

.word-break{
    word-break: break-word;
    white-space: pre-line;
    overflow-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.whatsapp-preview-wrapper{
    border:1px solid #ccc;
    border-radius: 5px;
    margin:10px;
    padding:10px;
    min-height: 370px;
}

.body-message-box{
    max-height: 300px;
    height: 100px
}