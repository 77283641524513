.slideshow-popup {
  position: fixed;
  z-index: 999;
  background: #000000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: 0 auto;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.post-wrapper {
  margin: "0 auto" !important;
  text-align: "center" !important;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  /* top: -100px; */
  width: auto;
  margin-top: -100px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  background: rgb(211, 5, 5);
}

/* Position the "next button" to the right */
.next {
  /* right: 5px; */
  border-radius: 3px 0 0 3px;
}
.prev {
  /* left: 5px; */
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.photo-cover {
  color: #000;
  font-size: 12px;
  padding: 10px;
  margin-bottom: 5px;
  width: auto;
  /* max-height: 400px;
  overflow-y: auto; */
}

.photo-cover .border {
  /* display:flex;
  align-items: stretch; */
}

.photo-cover img {
  /* max-height:200px; */
  max-width: 100%;
}

.photo-cover .cover-content {
  align-items: left;
  padding: 5px;
}

.cover-content .photo-title {
  font-weight: bold;
  text-align: left;
  font-size: 16px;
}

.slideshow-container .text {
  color: #f2f2f2;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 21px;
  width: 100%;
  text-align: left;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}
.closeButton {
  color: #f2f2f2;
  font-size: 20px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
  right: 0;
}
/* The dots/bullets/indicators */
.dot-container {
  position: relative;
  z-index: 999;
  top: -35px;
  background-color: rgba(0, 0, 0, 0.3);
  padding-top: 2px;
  margin: 6px;
}
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot:hover {
  background-color: #717171;
}

/* Fading animation*/
/* .fade {
  animation-name: fade;
  animation-duration: 0.5s;
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
} */

.pv-img {
  width: 100%;
  /* max-height:190px; */
  text-align: center;
  margin: 0 auto;
}
.img-thumb img {
  width: 60px;
  height: 60px;
}
.img-thumb i {
  visibility: hidden;
  position: absolute;
  margin: 5px;
}
.img-thumb:hover i {
  visibility: visible;
}
