@import "../../../Assets/Styles/variables.scss";

.setting-add-payment-method {
  border: 1px solid $border-color;
  text-align: center;
  padding: 3rem 2rem;
  margin: 1rem 0;

  .payment-icon {
    span {
      display: inline-flex;
      width: 60px;
      height: 60px;
      background: #e1effc;
      color: #4491e6;
      font-size: 2rem;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
    }
  }

  p {
    margin-top: 1rem;
    font-size: $text-size-sm;
  }
}
