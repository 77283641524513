.email-editor-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.email-editor-form,
.email-preview {
  /* width: 48%; */
}

.email-preview {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  align-self: flex-start;
  max-height: 600px;
  overflow-y: auto;
}

.email-preview-content {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;
  max-width: 600px;
  min-width: 300px;
  min-height: 400px;
}
.h3,
h3 {
  font-size: 1.25rem;
  font-weight: 600;
  font-family: "Public Sans", sans-serif;
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}
.email-img-wrapper {
  width: 100%;
  height: 60px;
  overflow: hidden;
}

.email-logo-img {
  max-width: 100%;
  height: 50px !important;
}
.message-body {
  min-height: 100px;
}
p {
  margin: 0px !important;
}
