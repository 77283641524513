.inputHidden {
  position: absolute;
  visibility: hidden;
  z-index: -1;
}
.uploadForm {
  visibility: hidden;
  position: absolute;
  z-index: -1;
}
.thumbsContainer {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
  width: auto;
}

.thumb {
  display: flex;
  border-radius: 2px;
  /* border: 1px solid #ccc; */
  margin-bottom: 8px;
  margin-right: 8px;
  width: auto;
  height: 60px;
  padding: 4px;
  box-sizing: border-box;
}

.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
  color: rgb(52, 124, 62);
}

.img {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 2px;
  border: 1px solid #ccc;

}

.fileDetails {
  display: flex;
  font-size: 10px;
  color: #000;
  line-height: 30px;
  margin-left: 5px;
  font-weight: bold;
}
.thumb-upload .fileDetails {
  display: block !important;
  line-height:normal !important;
}
.icon {
  display: block;
  font-size: 35px;
}


.thumb-upload {
  border-radius: 2px;
  position: relative;
  width: 80px;
  height: 80px;
  padding: 4px;
  box-sizing: border-box;
  text-align: center;
  display:flex;
  justify-content: center;
}

.thumb-upload .thumbInner {
  position: relative;
  display: block;
  overflow: hidden;
  color: rgb(52, 124, 62);
}

.thumb-upload .img {
  width: 100%;
  height: 100%;

}