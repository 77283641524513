@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Marck+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hurricane&family=Marck+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import "variables.scss";

:export {
  defaultColor: $default-color;
}

:root {
  font-size: 17px;
}

html {
  height: 100%;
}

body {
  height: 100%;
  // font-family: "Open Sans", sans-serif;
  font-family: "Public Sans", sans-serif;
  color: $default-color;
  font-size: 0.96rem;
  letter-spacing: 0.01rem;
  position: relative;
}

img {
  max-width: 100%;
  height: auto;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

a,
.nav-link {
  color: $primary-color;
}
.nav-link:hover {
  color: $primary-color;
  text-decoration: none;
}

/*/////// Common Style ////////*/
/* Heading style */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  font-family: "Public Sans", sans-serif;
  // color: $default-color;
}

.h1,
h1 {
  font-size: 2.25rem;
}

.h1,
h1 {
  font-size: 2.25rem;
}

/* 36px */
.h2,
h2 {
  font-size: 1.5rem;
}

/* 24px */
.h3,
h3 {
  font-size: 1.25rem;
}

/* 20px */
.h4,
h4 {
  font-size: 1.125rem;
}

/* 18 */
.h5,
h5 {
  font-size: 1rem;
}

/* 16 */
.h6,
h6 {
  font-size: 0.96rem;
}

/* 14 */

/* Text Size */
.text-size-xxxs {
  font-size: 0.6rem !important;
}

/* 12 */
.text-size-xxs {
  font-size: 0.75rem !important;
}

/* 12 */
.text-size-xs {
  font-size: 0.813rem !important;
}

/* 13 */
.text-size-x {
  font-size: 0.875rem !important;
}

/* 14 */
.text-size-xl {
  font-size: 0.938rem !important;
}

/* 15 */
.text-size-xxl {
  font-size: 1rem !important;
}

/* 16 */
.text-size-lg {
  font-size: 2rem !important;
}

/* 32 */
.text-size-xlg {
  font-size: 3.15rem !important;
}

/* 50 */

@media (max-width: 767px) {
  .h1,
  h1 {
    font-size: 2rem;
  }

  /* 36px */
  .h2,
  h2 {
    font-size: 1.25rem;
  }

  /* 24px */
  .h3,
  h3 {
    font-size: 1.15rem;
  }

  /* 20px */
  .h4,
  h4 {
    font-size: 1rem;
  }

  /* 18 */
  /* Text Size */
  .text-size-lg {
    font-size: 1.75rem !important;
  }

  /* 24 */
}

/* Conatiner */
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1300px;
  }
}
@media (min-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1500px;
  }
}

/**/
.icon-blue {
  color: $primary-color !important;
  border-color: $primary-color !important;
}

.icon-green {
  color: $active-green !important;
  // border-color: $active-green !important;
  background-color: rgba($active-green, 0.18) !important;
}

.icon-orange {
  color: $active-orange !important;
  // border-color: $active-orange !important;
  background-color: rgba($active-orange, 0.14) !important;
}

.icon-red {
  color: $color-red !important;
  border-color: $color-red !important;
}

.color-green {
  color: $color-green;
}

.color-orange {
  color: $color-orange;
}

.color-blue {
  color: $color-blue;
}

.color-light-green {
  color: $active-green;
}

.color-light-blue {
  color: $primary-color;
}

.color-red {
  color: $color-red;
}

.color-light-orange {
  color: $active-orange;
}

.color-light-red {
  color: $color-light-red;
}

.bg-primary {
  background-color: $primary-color !important;
}

.bg-warning {
  background-color: $warning-color !important;
}

.bg-orange {
  background-color: $color-orange !important;
}

.bg-green {
  background-color: $color-green !important;
}

.bg-blue {
  background-color: $primary-color !important;
}

.bg-red {
  background-color: $color-red !important;
}

.bg-light-red {
  background-color: $color-light-red !important;
}

/* Font Style */
.primary-font {
  font-family: "Open Sans", sans-serif !important;
}

.secondary-font {
  font-family: "Public Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.031rem;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

/* Text Color Style */
.text-success {
  color: $success-color !important;
}

.text-primary {
  color: $primary-color !important;
}

.text-danger {
  color: $danger-color !important;
}

.text-light {
  color: $border-color !important;
}

.text-extra-light {
  color: $extra-light-color !important;
}

.text-default {
  color: $default-color !important;
}

.text-light-brown {
  color: $light-brown-color !important;
}

.text-orange {
  color: $orange-color !important;
}

.text-purple {
  color: $purple-color !important;
}

/* Backgrounds Style */

.bg-dark {
  background-color: $bg-dark !important;
}

.bg-light {
  background-color: $extra-light-color !important;
}

.bg-medium {
  background-color: $bg-medium !important;
}

.bg-success {
  background-color: $success-color !important;
}

.bg-transparent {
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
}

.avtar-bg-orange {
  background-color: #fd8f6a !important;
}

.avtar-bg-green {
  background-color: #14c2c3 !important;
}

.avtar-bg-blue {
  background-color: #1890ff !important;
}

.avtar-bg-purple {
  background-color: #c284ff !important;
}

.avtar-bg-light-green {
  background-color: #86d261 !important;
}

.avtar-bg-yellow {
  background-color: #ffbf41 !important;
}

.avtar-bg-red {
  background-color: #ff5c66 !important;
}

.avtar-bg-light-blue {
  background-color: #35c0ab !important;
}

.avtar-bg-pink {
  background-color: #fd4fa7 !important;
}

.avtar-bg-light-red {
  background-color: #ff5f5f !important;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-event-none {
  pointer-events: none;
}

/* Width Sizes */
.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-90 {
  width: 90% !important;
}

/**/
.w-50p {
  width: 50px !important;
}

.w-100p {
  width: 100px !important;
}

.w-120p {
  width: 120px !important;
}

.w-150p {
  width: 150px !important;
}

.w-170p {
  width: 170px !important;
}

.w-200p {
  width: 200px !important;
}

.w-250p {
  width: 250px !important;
}

.border-lr {
  border-left: 0.063rem solid #e2e2e2;
  border-right: 0.063rem solid #e2e2e2;
}

/* hr style */
hr {
  border-top: 0.063rem solid #eaeaea;
}

.hr-vertical {
  border-left: 0.063rem solid #eaeaea;
  height: 100%;
}

.min-height-auto {
  .table-responsive {
    min-height: auto !important;
  }
}

/* Text Ellipsis */
.text-ellipsis span,
.text-ellipsis h5 .text-ellipsis h6 {
  display: inline;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.campaign-listing-line-clamp {
  > span,
  .text-ellipsis {
    display: -webkit-inline-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: break-spaces;
    max-width: 325px;
  }
}

.campaign-text-ellipsis p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
}

.name-with-avtar {
  .text-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: break-spaces;
  }
}

/* Button Style */
.btn {
  // font-family: "Montserrat", sans-serif;
  font-family: "Public Sans", sans-serif;
  font-size: 0.8rem;
  text-transform: capitalize;
  font-weight: 700;
  padding: 0.415rem 1.25rem;
  border-radius: 0.35rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.3;

  i {
    font-weight: normal;
    font-size: 0.985rem;
  }

  span ~ i,
  i ~ span {
    margin-left: 0.625rem;
  }

  &:focus {
    box-shadow: none;
  }

  &.btn-disable {
    border-color: $border-color;
    color: $extra-light-color;
    user-select: none;
    pointer-events: none;
    background-color: transparent;
  }
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  color: $default-color !important;
  border-color: $default-color !important;
  background-color: #e3e2fa !important;
  cursor: not-allowed;
}
.badge {
  background: #e0eefd;
  border: 1px solid $primary-color;
  color: $primary-color;
  padding: 5px 12px;
  font-size: $text-size-xxs;
  font-weight: 600;

  &.completed-badge {
    color: $color-green;
    border-color: $color-green;
    background: #eefdf2;
    min-width: 100px;
  }

  &.past-due-badge {
    color: $color-orange;
    border-color: $color-orange;
    background: #fff2dd;
    min-width: 100px;
  }

  &.cancelled-badge {
    color: $color-red;
    border-color: $color-red;
    background: #ffedef;
  }

  &.pending-badge,
  &.inactive-badge {
    color: $color-orange;
    border-color: $color-orange;
    background: #fff2dd;
  }

  &.lead-badge {
    color: #ff5c66;
    border-color: #ff5c66;
    background: #fff1f2;
  }

  &.upcoming-badge,
  &.completed-badge,
  &.past-due-badge,
  &.cancelled-badge,
  &.lead-badge,
  &.pending-badge,
  &.inactive-badge {
    min-width: 100px;
  }
  &.bg-success {
    border-color: $success-color;
    color: #fff;
  }
}

.disable-field {
  user-select: none;
  pointer-events: none;
}

.btn-w-icon {
  @include flex-center;

  span ~ i,
  i ~ span {
    margin-left: 0.625rem;
  }
}

.btn svg {
  margin-right: 0.313rem;
  margin-top: -0.063rem;
}

.btn-sm {
  font-size: 0.7rem;
  padding: 0.252rem 0.75rem;
}

.btn-lg {
  font-size: $text-size-xs;
  padding: 10px 20px;

  i {
    font-size: 1.25rem;
    font-weight: normal;
  }
}
.btn-check:checked + .btn-primary {
  background-color: $primary-color;
  border: 0.063rem solid $primary-color;
  color: #fff;
}
.btn-primary {
  background-color: $primary-color;
  border: 0.063rem solid $primary-color;
  color: #fff;

  &:hover,
  &:focus,
  &:active {
    background-color: $primary-color-active !important;
    border-color: $primary-color-active !important;

    svg {
      fill: #fff;
    }
  }
}
.btn-success {
  background-color: $success-color;
  border: 0.063rem solid $success-color;
  color: #fff;

  &:hover,
  &:focus,
  &:active {
    background-color: $success-color-active !important;
    border-color: $success-color-active !important;

    svg {
      fill: #fff;
    }
  }
}
.btn-check:checked + .btn-success {
  background-color: $success-color-active;
  border-color: $success-color-active;
}
.btn-check + .btn:hover {
  color: #fff;
}
.btn-warning {
  background-color: $warning-color;
  border: 0.063rem solid $warning-color;
  color: #fff;

  &:hover,
  &:focus,
  &:active {
    color: $warning-color !important;
    background-color: transparent !important;
    border-color: $warning-color !important;

    svg {
      fill: #fff;
    }
  }
}

.btn-outline-primary,
.btn-outline-primary.btn-primary {
  background-color: transparent;
  border: 0.063rem solid $primary-color;
  color: $primary-color;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background-color: $primary-color !important;
    border-color: $primary-color !important;

    svg {
      fill: #fff;
    }
  }
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #6e5dc6;
  border-color: #6e5dc6;
}

.btn-primary-1 {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;

  &:hover,
  &:focus,
  &:active {
    color: $primary-color !important;
    background-color: transparent !important;
    border-color: $primary-color !important;

    svg {
      fill: $primary-color;
    }
  }
}

.btn-outline-warning {
  background-color: transparent;
  border: 0.063rem solid $success-color;
  color: $success-color;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background-color: $success-color;
    border-color: $success-color;

    svg {
      fill: #fff;
    }
  }
}

.btn-outline-success {
  background-color: transparent;
  border: 0.063rem solid $success-color;
  color: $success-color !important;

  &:hover,
  &:focus,
  &:active {
    color: #fff !important;
    background-color: $success-color !important;
    border-color: $success-color !important;

    svg {
      fill: #fff;
    }
  }
}

.btn-outline-secondary {
  background-color: transparent;
  border: 0.063rem solid $default-color;
  color: $default-color;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background-color: $default-color;
    border-color: $default-color;

    svg {
      fill: #fff;
    }
  }
}

.btn {
  &.outline-facebook {
    border-color: $facebook-color;
    color: $facebook-color;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background-color: $facebook-color;
    }
  }

  &.outline-google {
    border-color: $google-color;
    color: $google-color;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background-color: $google-color;
    }
  }

  &.outline-linkedin {
    border-color: $linkedin-color;
    color: $linkedin-color;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background-color: $linkedin-color;
    }
  }

  &.outline-twitter {
    border-color: $twitter-color;
    color: $twitter-color;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background-color: $twitter-color;
    }
  }
}

.btn-link {
  color: $primary-color;

  &:hover {
    text-decoration: none;
    color: $primary-color;
  }

  &:focus {
    text-decoration: none;
  }
}

.btn-link-success {
  color: $success-color;

  &:hover {
    text-decoration: none;
    color: $success-color;
  }

  &:focus {
    text-decoration: none;
  }
}

.btn-link-dark {
  color: $default-color;

  &:hover {
    opacity: 0.8;
  }
}

.btn-link-2 {
  color: $default-color;
  padding: 0;
  font-weight: normal;
  text-transform: none;
  background: transparent;
  border: 0;
  font-size: 0.95rem;
  font-family: "Open Sans";

  &:hover,
  &:focus {
    background-color: transparent;
    color: $primary-color;
    outline: 0;
  }
}

.btn-icon {
  border: none;
  padding: 0.6rem 0.25rem;

  &:hover {
    background: none;
    color: inherit;
  }

  i {
    font-size: 1.5rem;
  }

  &.btn-outline-danger {
    &:hover {
      color: $danger-color;
    }
  }

  &.btn-outline-success {
    &:hover {
      color: $success-color;
    }
  }
}

.btn-circle {
  &.btn-sm {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    box-shadow: 0 0 0 3px rgba($success-color, 0.2);
  }
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  background-color: $primary-color;
  border-color: $border-color;
}

.btn-group-toggle,
.btn-group {
  .btn-outline-dark {
    background-color: #fff;
    border-color: $border-color;
    color: $light-color;
    padding: 0.57rem 1.5rem;
  }

  .btn-check + .btn:hover {
    border-color: $border-color;
  }
}

.btn-cstm {
  display: inline-flex;
  font-weight: 600;
  border-radius: 6px;
}

.btn span ~ i,
.btn i ~ span {
  margin-left: 0.4rem;
}

.btn-danger {
  background-color: #dc3545;
  border: 0.063rem solid #dc3545;
  color: #fff;
}

.btn-danger:hover,
.btn-danger:active {
  color: #dc3545;
  background-color: transparent;
  border-color: #dc3545;
}

.btn-outline-white {
  background-color: transparent;
  border: 0.063rem solid #fff;
  color: #fff;
}

.btn-outline-white:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-reset {
  background: none;
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
}

.btn-reset:hover,
.btn-reset:focus {
  background: none;
  border: none;
  outline: none;
}

.btn-reset i {
  font-size: 20px;
  font-weight: normal;
}

.nxt-prv-btns {
  border: 1px solid $primary-color;
  border-radius: 6px;
}

.nxt-prv-btns {
  .btn {
    padding: 3px 10px;
    height: 32px;

    &.btn-primary-1 {
      &:hover,
      &:focus {
        background-color: $primary-color;
        color: #fff;
      }
    }
  }
}

.nxt-prv-btns .btn i {
  font-size: 22px;
  line-height: 0.92;
}

@media (max-width: 767px) {
  .btn {
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    span ~ i,
    i ~ span {
      margin-left: 0.25rem;
    }
  }
}

@media (max-width: 599px) {
  .btn {
    font-size: $text-size-xxs;

    i {
      font-size: $text-size-xs;
    }

    span {
      display: none;
    }

    // Btn Mobile Show Icons

    &.btn-ms-icon {
      span {
        display: inline;
      }
    }
  }

  .sub-header {
    .btn {
      .ri-arrow-left-s-line {
        font-size: $text-size-sm;
      }
    }
  }
}

/**/
.tag {
  display: inline-block;
  padding: 0.46rem 1.1rem 0.46rem 0.6rem;
  font-size: $text-size-xxs;
  font-weight: 500;
  color: $primary-color;
  text-align: left;
  vertical-align: baseline;
  border-radius: 0.25em;
  margin-right: 0.625rem;
  max-width: 13rem;
  // text-overflow: ellipsis;
  // overflow: hidden;
  position: relative;
  border: 0.063rem solid $light-color;
  margin-bottom: 0.25rem;

  strong {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    span {
      font-weight: normal;
    }
  }

  i {
    position: absolute;
    top: 0.5rem;
    right: 1px;
    cursor: pointer;
  }

  &.tag-info {
    background-color: $light-color;
    border: 0.063rem solid #dce4ec;
    color: $primary-color;
  }
}

/* Form Style */
input::-webkit-input-placeholder {
  color: #979ca4 !important;
}

::-moz-placeholder {
  color: #979ca4 !important;
}

:-ms-input-placeholder {
  /** notice that ie has only a single colon) */
  color: #979ca4 !important;
}

::-webkit-input-placeholder {
  color: #979ca4 !important;
}

::placeholder {
  color: #979ca4 !important;
}

.form-group {
  position: relative;

  .form-label {
    font-family: "Public Sans", sans-serif;
    font-weight: 600;
    font-size: 0.825rem;
    margin-bottom: 0.3rem;
    color: $default-color;
    letter-spacing: 0.03rem;
  }

  .input-group {
    .css-2b097c-container {
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
    }

    .input-group-text {
      margin-bottom: 0;
      i {
        color: $primary-color;
        font-weight: 500;
      }
    }
  }

  .form-control,
  .css-2b097c-container {
    font-family: "Public Sans", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
  }

  input.form-control,
  select.form-control {
    height: 40px;
  }
}

textarea.form-control {
  min-height: 100px;
}

.form-control {
  font-family: "Open Sans", sans-serif;
  font-size: $text-size-xs;
  // height: calc(1.6em + 0.75rem + 2px);
  border-radius: 0.35rem;
  padding: 0.375rem 0.85rem;
  border: 0.063rem solid $border-color;
  color: $default-color;

  /*-webkit-text-stroke: 0.1px;
  -webkit-text-stroke-color: $default-color;*/
  &:focus {
    box-shadow: 0 0 0;
    border-color: $primary-color;
  }
}

.content-editable {
  min-height: 5rem;
  border-bottom: 0.063rem solid $border-color;
  color: $default-color;

  &:focus {
    outline: none;
  }
}

.form-control-sm {
  font-size: $text-size-xs;
  height: calc(1.2em + 0.75rem + 2px);
}

/* Field With Icon */
.form-group-with-icon {
  position: relative;

  .form-control {
    padding-left: 2rem;
  }

  .field-icon {
    position: absolute;
    left: 10px;
    bottom: 0;
    height: 37px;
    line-height: 37px;
    color: $primary-color;
  }

  .input-group-text {
    i {
      color: $primary-color;
    }
  }
}

// Icons Right Side

.field-icon-right {
  position: relative;

  .icon-right {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    max-width: 30px;
  }
}

#input-group-dropdown-protocol, .dropdown-toggle {
  text-transform: unset !important;
}

.edit-field-details {
  label {
    display: block;
  }

  .change-link {
    float: right;
    font-size: 10px;
    color: $primary-color;
    padding-top: 0.5rem;
    cursor: pointer;
    font-weight: 600;
  }
}

.card-info-icon {
  width: 25px;
  display: inline-block;
  margin-right: 10px;
}

/* Select */
.select-field {
  position: relative;
  border-radius: 0.15rem;
  background-color: #fff;

  .form-control {
    padding-right: 40px;
    appearance: none;
    position: relative;
    z-index: 1;
    background-color: transparent;

    &:focus {
      ~ i {
        opacity: 1;
      }
    }
  }

  i {
    position: absolute;
    right: 13px;
    top: 6px;
    z-index: 0;
    font-size: 1.125rem;
    opacity: 0.75;
  }
}

/* Fieldset */
.custom-fieldset {
  border: 1px solid $border-color;
  padding: 0.5rem 1rem;
  margin-bottom: 25px;

  legend {
    font-family: "Public Sans", sans-serif;
    font-weight: 700;
    font-size: 0.688rem;
    margin-bottom: 0.3rem;
    color: #7f8590;
    text-transform: uppercase;
    letter-spacing: 0.03rem;
    width: auto;
    padding: 0 0.75rem;
  }
}

/* Search Box  */

.search-field {
  position: relative;
  text-transform: capitalize;

  .form-control {
    padding-left: 40px;

    &:focus {
      ~ i {
        opacity: 1;
      }
    }
  }

  button {
    height: 36px;
  }

  i {
    position: absolute;
    left: 13px;
    top: 6px;
    font-size: 1.125rem;
    opacity: 0.75;
    cursor: pointer;
  }
}

.form-row {
  .row {
    margin-left: -5px;
    margin-right: -5px;

    > [class*="col"] {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}

/* Select */

.input-group-text {
  padding-top: 0.275rem;
  padding-bottom: 0.275rem;
  border-bottom-left-radius: 0.15rem;
  border-top-left-radius: 0.15rem;
  font-size: $text-size-xs;
  font-weight: 600;
  opacity: 1;
  background-color: $bg-light !important;
}

.css-yk16xz-control {
  border-radius: 0.15rem !important;
  border: 0.063rem solid $border-color !important;
  min-height: calc(1.56em + 0.75rem + 2px) !important;
}

.css-1pahdxg-control {
  min-height: calc(1.6em + 0.75rem + 2px) !important;
}

.css-26l3qy-menu {
  z-index: 3 !important;
}

.css-4ljt47-MenuList {
  max-height: 150px !important;
}

.css-g1d714-ValueContainer {
  padding-left: 0.85rem !important;
  padding-right: 0.85rem !important;
  height: 34px;
}

css-1wa3eu0-placeholder {
  white-space: nowrap;
}

.css-1uccc91-singleValue {
  color: $default-color !important;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-1hb7zxy-IndicatorsContainer {
  height: 34px;

  > div {
    padding: 0 !important;

    &:last-child {
      padding-right: 8px !important;
    }
  }
}

.css-tlfecz-indicatorContainer {
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.css-1rhbuit-multiValue {
  background-color: #e0ebf8 !important;
  border: 0.063rem solid #dce4ec !important;
  color: #6c8cb5;
  margin: -5px 14px 0 -9px !important;

  .css-12jo7m5 {
    color: #6c8cb5;
  }

  .css-xb97g8 {
    &:hover {
      background-color: transparent;

      svg {
        fill: #6c8cb5;
      }
    }
  }
}

.react-autosuggest__container {
  position: relative;

  .react-autosuggest__suggestions-container {
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 1;
    width: 100%;

    ul {
      max-height: 100px;
      overflow: auto;
      list-style: none;
      border: 0.063rem solid $border-color;
      padding: 0;
      margin: 0;

      li {
        padding: 5px 15px;
      }
    }
  }
}

/* Date Picker */
.DateRangePicker {
  width: 100%;

  .DateRangePickerInput {
    width: 100%;
    height: calc(1.6em + 0.75rem + 2px);
    border-radius: 0.15rem;
    padding: 0.375rem 0.85rem;
    border: 0.063rem solid $border-color;
    @include flex-center;

    .DateInput__small {
      width: auto;
      flex-basis: 0;
      flex-grow: 1;
      min-width: 0;
      max-width: 100%;
      color: $default-color;
    }

    .DateRangePickerInput_arrow {
      padding: 0 20px;
    }
  }
}

.SingleDatePicker {
  width: 100%;

  .SingleDatePickerInput {
    width: 100%;
    height: calc(1.6em + 0.75rem + 2px);
    border-radius: 0.15rem;
    padding: 0.375rem 0.85rem;
    border: 0.063rem solid $border-color;
    @include flex-center;

    .DateInput__small {
      width: auto;
      flex-basis: 0;
      flex-grow: 1;
      min-width: 0;
      max-width: 100%;
      color: $default-color;
    }
  }
}

/* Custom Checkbox  */

.custom-checkbox-1 {
  padding-left: 30px;

  label {
    position: relative;
  }

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label::before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #8b8b8b;
    position: absolute;
    cursor: pointer;
    left: -30px;
    top: 50%;
    transform: translate(-0%, -50%);
    width: 18px;
    height: 18px;
    border-radius: 4px;
  }

  input:checked + label:before {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: calc(50% - 6px);
    left: -23px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.custom-radio-1 {
  padding-left: 30px;

  label {
    position: relative;
  }

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label::before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #8b8b8b;
    position: absolute;
    cursor: pointer;
    left: -30px;
    top: 50%;
    transform: translate(-0%, -50%);
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }

  input:checked + label:before {
    border-color: $primary-color;
  }

  input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: calc(50% - 5px);
    left: -26px;
    width: 10px;
    border-radius: 50%;
    height: 10px;
    background: #78b0ed;
    border-width: 0 2px 2px 0;
  }
}

/* Form Control View Style  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.form-group-view {
  position: relative;
  margin-bottom: 0.7rem;

  .form-control-view {
    position: relative;

    .form-label {
      margin-bottom: 0;
    }

    .form-control,
    .rc-time-picker-input,
    .rdatez input {
      border-color: transparent;
      border-width: 0.094rem !important;
      background-color: transparent;
      padding-left: 5px;
      padding-right: 0;
      padding-top: 0;
      font-size: 1rem;

      &:hover {
        border-bottom-color: $border-color !important;
      }

      &:focus {
        box-shadow: none;
        border-bottom-color: $primary-color !important;
      }
    }

    .css-yk16xz-control {
      border-width: 0.094rem !important;
      background-color: transparent !important;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-bottom-color: transparent !important;
      box-shadow: none !important;
      font-size: 1rem;

      &:hover {
        border-bottom-color: $border-color !important;

        .css-1hb7zxy-IndicatorsContainer {
          opacity: 1;
        }
      }

      &:focus {
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        box-shadow: none !important;
        border-bottom-color: $primary-color;
      }

      .css-g1d714-ValueContainer {
        padding-left: 0 !important;
      }

      .css-1hb7zxy-IndicatorsContainer {
        opacity: 0;
      }
    }

    .css-1pahdxg-control {
      border-width: 0.094rem;
      background-color: transparent !important;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      box-shadow: none !important;
      border-bottom-color: $primary-color;
      font-size: 1rem;

      .css-g1d714-ValueContainer {
        padding-left: 0 !important;
      }
    }

    i {
      position: absolute;
      right: 5px;
      top: 9px;
      color: $primary-color;
      opacity: 0;
    }
  }

  .form-control-view,
  .form-control-view-select,
  .view-select-date {
    &:hover {
      i {
        opacity: 1;
      }
    }
  }
}

.form-control-view .form-control:hover ~ i {
  opacity: 1;
}

.form-control-view .form-control:focus ~ i {
  opacity: 0;
}

.view-select-date {
  .input-group-text {
    display: none;
  }

  input {
    border: 0 !important;
    padding: 0 !important;
    font-weight: 500;
    font-family: "Montserrat", sans-serif !important;
    font-size: 1rem !important;
  }

  .input-group {
    display: block;
  }
}

.minus-plus-action {
  .input-group-text {
    width: 42px;
    justify-content: center;
    cursor: pointer;

    span {
      line-height: 0.5;
      margin: 0;
    }
  }
}

/* Group inputs in ROW with low padding  */
.group-fields .row {
  margin-right: -5px;
  margin-left: -5px;

  > div {
    padding-right: 5px;
    padding-left: 5px;
  }
}

/* Tooltip Style  */

// .tooltip-inner {
//   max-width: 20rem;
//   padding: 0.45rem 0.7rem;
//   font-size: 0.75rem;
//   background-color: #1d2737;
// }

// .tooltip[x-placement^="top"] .arrow::before {
//   border-top-color: #1d2737;
// }

// .tooltip[x-placement^="right"] .arrow::before {
//   border-right-color: #1d2737;
// }

// .tooltip[x-placement^="bottom"] .arrow::before {
//   border-bottom-color: #1d2737;
// }

// .tooltip[x-placement^="left"] .arrow::before {
//   border-left-color: #1d2737;
// }

/* Tooltip Style 2*/

.tooltip {
  .tooltip-inner {
    background-color: $primary-color;
    font-size: $text-size-xs;
    padding: 0.8rem 1rem;
    color: #fff;
    box-shadow: 0 0 15px rgba($primary-color, 0.3);
    font-weight: 500;
    font-family: $font-montserrat;
    opacity: 1;
    max-width: 300px;

    a,
    .btn-link {
      color: #fff;
    }
  }

  &.show {
    opacity: 1 !important;
  }
}

.tooltip[x-placement^="top"] .arrow::before,
.tooltip[x-placement^="top"] .tooltip-arrow::before {
  border-top-color: $primary-color;
}

.tooltip[x-placement^="right"] .arrow::before,
.tooltip[x-placement^="right"] .tooltip-arrow::before {
  border-right-color: $primary-color;
}

.tooltip[x-placement^="bottom"] .arrow::before,
.tooltip[x-placement^="bottom"] .tooltip-arrow::before {
  border-bottom-color: $primary-color;
}

.tooltip[x-placement^="left"] .arrow::before,
.tooltip[x-placement^="left"] .tooltip-arrow::before {
  border-left-color: $primary-color;
}

/* Divider Style */
.or-divider {
  display: block;
  position: relative;
  font-size: 0.625rem;
  margin: 0.375rem 0;

  strong {
    display: inline-block;
    background-color: #fff;
    position: relative;
  }

  strong:after {
    content: "";
    position: absolute;
    right: calc(100% + 5px);
    top: 50%;
    margin-top: -0.5px;
    height: 0.063rem;
    width: 3rem;
    background-color: #c7cad0;
  }

  strong:before {
    content: "";
    position: absolute;
    left: calc(100% + 5px);
    top: 50%;
    margin-top: -0.5px;
    height: 0.063rem;
    width: 3rem;
    background-color: #c7cad0;
  }
}

.or-circle {
  display: inline-flex;
  position: relative;
  font-size: 0.75rem;
  margin: 0.375rem 0;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  border: 0.063rem solid $border-color;
  background-color: $bg-light;
  align-items: center;
  justify-content: center;
}

.and-divider {
  display: block;
  position: relative;
  font-size: 0.625rem;
  margin: 0.375rem 0;
  text-align: center;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -0.5px;
    height: 0.063rem;
    width: calc(50% - 1.1rem);
    background-color: $border-color;
  }

  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -0.5px;
    height: 0.063rem;
    width: calc(50% - 1.1rem);
    background-color: $border-color;
  }

  span {
    background-color: #fff;
    border: 1px solid $border-color;
    border-radius: 50%;
    width: 2.2rem;
    height: 2.2rem;
    display: block;
    margin: 0 auto;
    font-size: 1rem;
    line-height: 2rem;
  }

  span.divider {
    position: relative;
    z-index: 99;
    background-color: rgb(248, 248, 248);
    border: 1px solid $border-color;
    border-radius: 20px;
    width: 6rem;
    height: 1.5rem;
    // display: block;
    margin: 0 auto;
    font-size: 0.8rem;
    line-height: 1.3rem;
  }
}

/* Table Style */
.table-wrap {
  border: 0.063rem solid #eaeaea;
  border-top: none;

  .table-responsive {
    //max-height: 60vh;
    min-height: 350px;
    //   min-height: 580px;
  }
}

.engage-conver-body {
  overflow: auto;
  .table-wrap {
    .table-responsive {
      min-height: auto;
    }
  }
}

.conversation-list-table {
  .table-responsive {
    max-height: 60vh;
    min-height: 300px;
  }
}

.table {
  border-bottom: 0.063rem solid #eaeaea;

  td,
  th {
    border-top: 0.063rem solid #eaeaea;
    padding: 0.5rem 1.875rem;
    height: 50px;
  }
  td {
    font-size: 0.85rem;
  }
  thead {
    th {
      vertical-align: middle;
      border-bottom: 0.063rem solid #eaeaea;
      font-family: "Public Sans", sans-serif;
      font-size: 0.8rem;
      text-transform: uppercase;
      font-weight: 700;
      color: #656769;
      white-space: nowrap;

      .table {
        color: #656769;
      }
    }
  }
}

.table-align-middle {
  td,
  th {
    vertical-align: middle;
  }
}

.custom-table {
  td {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // word-break: keep-all;
    // z-index: 2;

    // span {
    //   vertical-align: middle;
    // }

    .small-avtar {
      margin-right: 10px;
    }
  }

  .preview-hover {
    max-width: 350px;
    min-width: 320px;
  }

  .campaign-td {
    max-width: 325px;
  }
}

.table {
  td .btn-link {
    padding: 0;
    float: right;
    font-size: 0.75rem;
  }

  .custom-control.custom-checkbox {
    margin-bottom: 0;
  }

  .name-with-avtar {
    width: 200px;
  }
}

.table-sm {
  td,
  th {
    padding: 0.625rem 1.5rem;
    height: 3.5rem;
  }
}

.vertical-bars {
  width: 1.25rem;
  height: 0.875rem;
  border-left: 0.1875rem solid #656769;
  display: inline-flex;
  position: relative;
  top: -3px;
  &::after,
  &::before {
    content: "";
    width: 0.1875rem;
    height: 0.875rem;
    background-color: #656769;
    display: inline-block;
    margin-left: 0.2995rem;
  }
}

@media (min-width: 1440px) {
  .vertical-bars {
    &::after {
      margin-left: 0.25rem;
    }
  }
}

/* Child Table ( Extend Table ) */
.child-table {
  margin-bottom: 0;
  border-bottom: none;

  td,
  th {
    height: 50px;

    &:first-child {
      /*padding-left: 0;*/
    }

    &:last-child {
      /*padding-right: 0;*/
    }
  }

  th {
    border-top: none;
  }
}

.extend-table {
  background-color: $bg-light;
}

/* Sticky Style */
.table-th-sticky {
  border-top: 0.063rem solid #eaeaea;
  position: relative;

  thead {
    border-bottom: 0.063rem solid #eaeaea;

    th {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 4;
      border-top: none;
      box-shadow: 0 0.063rem 0 #eaeaea;
    }
  }

  .checkbox-td {
    z-index: 7;
  }
}

.action-col-sticky {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  margin-right: 0px;
  background-color: #fff;
  padding-left: 0.4rem !important;
  padding-right: 1rem !important;
  z-index: 8;
  width: 45px;
  max-width: 45px !important;
  z-index: 0;
}

.action-sticky {
  position: sticky;
  right: 0;
  top: 0;
  width: 45px;
  max-width: 45px !important;
  padding-left: 0.4rem !important;
  padding-right: 1rem !important;
}

.widget-loader-bg {
  background-color: transparent !important;
  box-shadow: 0 0 0 !important;
  border: 1px solid #dee2e6;

  .card_main {
    border: 0 !important;
  }

  .form-group {
    > div {
      > div {
        background-color: transparent;
      }
    }
  }
}

/* Card Style */
.operations-actions-wrap {
  position: relative;
  display: none;

  .contact-operations-actions {
    display: flex;
    margin-top: 0.5rem;
    z-index: 1;
  }
}

.operations-show {
  th {
    > span {
      opacity: 0;
      visibility: hidden;
    }
  }

  .operations-actions-wrap {
    display: inline-block;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 6;
    padding-left: 62px;
    padding-bottom: 9px;
    background-color: #fff;
    border-bottom: 0;
    vertical-align: middle;

    .contact-operations-actions {
      // opacity: 1;
      // visibility: visible;
      height: 100%;
    }
  }

  .operations-actions-th {
    opacity: 0;
    visibility: hidden;
    //display: table-column;
  }
}

/* Card Style */
.card {
  border: 0.063rem solid #eaeaea;
  border-radius: 10px;
  ~ .card {
    margin-top: 1.5rem;
  }

  .card-header {
    padding: 1.75rem;
    background-color: transparent;
    border-bottom: 1px solid $border-color;
  }

  .card-body {
    padding: 1.75rem;

    .card-header {
      padding: 0 0 0.75rem 0;
      margin-bottom: 1.25rem;
    }
  }

  &.card-shadow {
    box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.05);
  }
}

.card-style-2 {
  position: relative;
  border-radius: 15px;
  box-shadow: 0 0 4px 0 #dae3ecba;
  border: 0;
  height: 100%;
}

@media (max-width: 1240px) {
  .card {
    .card-body {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

/* Social Media Circle Style */
.social-media-circle-icons {
  padding: 0.125rem 0 0 0;

  a {
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    background-color: #ccc;
    margin: 0 0.125rem;
    line-height: 1.375rem;
    color: #fff;
    vertical-align: middle;
    text-decoration-line: none;

    &:hover {
      text-decoration: none;
    }
  }

  a.facebook {
    background-color: $facebook-color;
  }

  a.twitter {
    background-color: $twitter-color;
  }

  a.google {
    background-color: $google-color;
  }

  a.linkedin {
    background-color: $linkedin-color;
  }

  a.skype {
    background-color: #3e9dd7;
  }
}

/* Border Style */
.border-left {
  border-left: 0.063rem solid $border-color !important;
}

.border-right {
  border-right: 0.063rem solid $border-color !important;
}

.border-top {
  border-top: 0.063rem solid $border-color !important;
}

.border-bottom {
  border-bottom: 0.063rem solid $border-color !important;
}

.border-all {
  border: 0.063rem solid $border-color !important;
}

/* Shadow Style */
.shadow {
  box-shadow: 0 0.5rem 0.8rem rgba(0, 0, 0, 0.06) !important;
}

/*/////// Common Style ////////*/
#root {
  width: 100%;
  height: 100%;
}

.main-content {
  padding-bottom: 30px;
  min-height: calc(100vh - 127.86px);
}

.sub-head {
  padding: 3.2vh 0 0.625rem 0;
}

/* Container Style */
@media (min-width: 768px) {
  .container-fluid {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
}

@media (min-width: 1441px) {
  .container-fluid {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}

/*/////// Drawer Style ////////*/
.modal {
  .row {
    margin-left: -10px;
    margin-right: -10px;

    [class*="col"] {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.modal.right .modal-dialog {
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

/* Header */
.modal-header {
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 0;
}

/* Right */
.modal.right {
  .modal-dialog {
    position: fixed;
    margin: auto;
    width: 660px;
    max-width: 100%;
    height: 100%;
  }

  .modal-content {
    height: 100%;
    overflow-y: auto;
  }

  &.fade {
    .modal-dialog {
      right: -660px;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
      -webkit-transition:
        opacity 0.3s linear,
        right 0.3s ease-out;
      -moz-transition:
        opacity 0.3s linear,
        right 0.3s ease-out;
      -o-transition:
        opacity 0.3s linear,
        right 0.3s ease-out;
      transition:
        opacity 0.3s linear,
        right 0.3s ease-out;
    }

    &.show .modal-dialog {
      right: 0;
    }
  }

  .modal-content {
    border-radius: 0;
    border: none;
  }
}

/* Size */
.modal.common {
  &.modalSm {
    .modal-dialog {
      max-width: 600px;
    }
  }

  &.modalMd {
    .modal-dialog {
      max-width: 700px;
    }
  }

  &.modalLg {
    .modal-dialog {
      max-width: 816px;
    }
  }
}

.modal.common {
  &.modalLlg {
    .modal-dialog {
      max-width: 1000px;
    }
  }
}

.modal.common {
  &.modalXl {
    .modal-dialog {
      max-width: 1140px;
    }
  }
}

.modal.common {
  &.modalDxl {
    padding: 2rem;

    .modal-dialog {
      max-width: 100%;
    }
  }
}

.modal.right {
  &.modalLg {
    .modal-dialog {
      width: 900px;
    }
  }
}

.modal.right {
  &.modalXl {
    .modal-dialog {
      width: 1140px;
    }
  }
}

/* Full Screen */

.modal.full {
  .modal-dialog {
    position: fixed;
    margin: auto;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  .modal-content {
    height: 100%;
    overflow-y: auto;
  }

  .close-modal {
    position: fixed;
    top: 0;
    right: 3rem;
  }
}

/* Body */

.modal-body {
  padding-top: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1.5rem;
}

/* Footer */

.modal-footer {
  padding-left: 2rem;
  padding-right: 2rem;
  border-top: none;
}

.modal-footer-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-radius: 0;
}

/* Style */

.modal.right .modal-header .close {
  margin: 0;
  display: flex;
  padding: 0;
  font-family: "Public Sans", sans-serif;
  background: none;

  &:focus {
    color: #9599a2;
  }

  i {
    font-size: 1rem;
    position: relative;
    top: -1px;
    margin-right: 5px;
  }
}

.modal-content {
  background-color: #fbfbfb;
  border: 0.063rem solid #eaeaea;
}

/* Common Style */

.modal.common {
  .modal-dialog {
    max-width: 550px;

    .modal-content {
      border: none;
      border-radius: 0;
      padding: 0 10px;
      background: none;

      .modal-header {
        .modal-title {
          font-size: 0.875rem;
          margin-top: 0.25rem;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .modal-header,
    .modal-body,
    .modal-footer {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }

  .modal-header .close {
    color: #fff;
    font-weight: normal;
    opacity: 0.9;

    &:focus {
      outline: none;
    }
  }

  .modal-body {
    border: 0.063rem solid #eaeaea;
    background-color: #fff;
  }

  .modal-footer {
    background-color: #fbfbfb;
    border: 0.063rem solid #eaeaea;
    border-top: none;
  }
}

.modal.common.fixed-height-with-scroll {
  .modal-body {
    max-height: 500px;
    overflow: auto;
  }
}

/* More Dotted Dropdown Style */
.more-dropdown {
  .dropdown-toggle {
    padding: 0;

    i {
      font-size: 1.35rem;
      color: #656769;
    }

    &::after {
      display: none;
    }
    &:active {
      outline: none;
      border: none;
    }
  }
  .dropdown-menu {
    padding: 0;
    min-width: 11.5rem;
    border: 1px solid #eaeaea;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

    .dropdown-item {
      border-bottom: 1px solid #eaeaea;
      display: flex;
      align-items: center;
      padding-left: 1.15rem;
      padding-right: 1.15rem;
      font-size: $text-size-xs;
      span {
        font-size: $text-size-xs;
        font-weight: 400 !important;
      }
      i {
        font-size: 1.125rem;
        margin-right: 8px;
      }
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      right: 12px;
      bottom: 100%;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid #eaeaea;
      margin-bottom: 2px;
    }

    &::after {
      border-bottom: 9px solid #fff;
      margin-bottom: 0;
    }
  }
}

.td-more-dropdown {
  display: inline-block;
  width: auto;

  .dropdown-menu {
    left: 10px !important;
  }
}

.td-more-dropdown .dropdown-menu[x-placement^="top-end"]::before,
.td-more-dropdown .dropdown-menu[x-placement^="top-end"]::after {
  border-bottom: none;
  border-top: 9px solid $border-color;
  bottom: auto;
  top: 100%;
}

.td-more-dropdown .dropdown-menu[x-placement^="top-end"]::after {
  border-top-color: #fff;
  margin-top: -1px;
}

// .dropdown-menu-right {
//   left: 10px !important;
// }
/*/////// Integrate Email Drawer Style ////////*/
.integrate-via-wrap {
  width: 340px;
  max-width: 100%;
}

.btn-outline-gmail {
  border-color: $google-color;
  color: $google-color;

  &:hover,
  &:focus {
    background-color: $google-color;
    color: #fff;
  }
}

.btn-outline-outlook {
  border-color: #2474bb;
  color: #2474bb;

  &:hover,
  &:focus {
    background-color: #2474bb;
    color: #fff;
  }

  &:hover svg,
  &:focus svg {
    fill: #fff;
  }

  img {
    width: 22px;
    margin: 4px 10px;
  }
}

.skip-btn {
  &:hover {
    text-decoration: underline;
  }
}

// Filters
.bared-filter {
  .form-group {
    margin-bottom: 0;
  }
}

.sub-header-filter {
  border-top: 0.063rem solid transparent;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-out;
  position: relative;
  z-index: 9;

  .form-group {
    margin-bottom: 0;
    position: relative;
    /*0.5rem*/
  }

  &.show {
    display: block;
    max-height: 250px;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top-color: #eaeaea;
    overflow: visible;
  }

  .for-form-control {
    .react-autosuggest__input {
      @include form-control;
    }
  }

  .form-row {
    .btn {
      height: 37px;
    }
  }
}

@media (max-width: 575px) {
  .sub-header-filter {
    .form-group {
      margin-bottom: 0.5rem;
    }
  }
}

@media (max-width: 575px) {
  .sub-header-filter {
    .form-group {
      .form-label {
        display: none;
      }
    }
  }
}

.filter-tags-sec {
  display: flex;
  align-items: flex-start;
  width: auto;
  max-width: 100%;
  gap: 3rem;

  .save-filter-form {
    display: flex;
    flex-wrap: nowrap;

    .form-control {
      padding-right: 0.85rem;
      height: 33px;
    }

    .btn {
      white-space: nowrap;
      height: 33px;
    }
  }
}

.filter-tags-wrap {
  position: relative;
  padding-right: 0;
  padding-top: 1px;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  .tag {
    margin-bottom: 0;
    margin-right: 0;
    padding-right: 1.5rem;

    i {
      top: 0.55rem;
      right: 7px;
    }
  }

  .tagsmore {
    right: 8px;
    top: 0;
    margin-right: 10px;

    .dropdown-toggle {
      color: #aaaaaa;
      height: 30px;

      i {
        font-size: 1.25rem;
      }

      &:after {
        content: "";
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #ddd;
      }
    }

    &.show .dropdown-toggle {
      background-color: #e0ebf8;
    }

    .dropdown-menu {
      background-color: #e0ebf8;
      border: 0.063rem solid #dce4ec;
      color: #6c8cb5;
      left: 10px !important;

      &:after {
        border-bottom-color: #e0ebf8;
      }

      a {
        font-size: 0.75rem;
        color: #6c8cb5;
        position: relative;
        border-bottom: 0.063rem solid #dce4ec;
        padding: 0.35rem 2.5rem 0.35rem 0.95rem;

        i {
          position: absolute;
          top: 6px;
          right: 7px;
          font-weight: 700;
          font-size: 0.75rem;
        }

        &:active {
          background-color: #f8f9fa;
        }
      }
    }
  }
}

.add-filter-and-or {
  select {
    height: 100%;
    font-size: 0.7rem;
    font-weight: 600;
    margin-top: 0px;
    min-width: 70px;
  }
}

// Filters End

/*/////// CRM List Style ////////*/
.sub-header {
  padding: 1rem 0;
  margin-bottom: 3.125rem;
  border-bottom: 0.063rem solid #eaeaea;
  position: relative;
  background-color: #fff;
  z-index: 99;
  // box-shadow: 0 4px 15px rgba(0, 0, 0, 0.075);
}

.sub-header-title-wrap p {
  margin-bottom: 1px;
  padding-left: 5px;
}

.add-filters-btn {
  position: absolute;
  left: 50%;
  bottom: -26px;
  width: auto;
  // margin-left: -75px;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 700;
  background-color: transparent;
  border: none;
  letter-spacing: 0.5px;
  color: $success-color;
  transform: translate(-50%, 0);
  padding: 12px 8px 0 8px;

  &:before {
    content: "";
    position: absolute;
    left: 2px;
    top: 12px;
    width: 7px;
    height: 7px;
    background-color: #fbfbfb;
    border-radius: 0 0 0 4px;
  }

  &:after {
    content: "";
    position: absolute;
    right: 2px;
    top: 12px;
    width: 7px;
    height: 7px;
    background-color: #fbfbfb;
    border-radius: 0 0 4px 0;
  }

  span {
    border: 0.063rem solid #eaeaea;
    border-top: none;
    display: block;
    border-radius: 0 0 8px 8px;
    padding: 0 17px 8px 17px;
    background-color: $extra-light-color;
    white-space: nowrap;
    // box-shadow: 0 7px 15px rgba(0, 0, 0, 0.075);
    background-color: #fff;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 15px;
      z-index: 1;
      width: 8px;
      height: 8px;

      border-top-right-radius: 8px;
      border-width: 1px 0 0 1px;
      box-shadow: 1px -1px 0 #eaeaea;
    }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 15px;
      z-index: 1;
      width: 8px;
      height: 8px;

      border-top-left-radius: 8px;
      border-width: 1px 0 0 1px;
      box-shadow: -1px -1px 0 #eaeaea;
    }
  }

  i {
    position: relative;
    top: 1px;
    margin-right: 2px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    color: #4d5768;
    text-decoration: none;
  }
}

@media (max-width: 1440px) {
  .add-filters-btn {
    bottom: -25px;
  }
}

/**/
.sub-header-filter {
  border-top: 0.063rem solid transparent;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-out;
  position: relative;
  z-index: 9;

  .form-group {
    margin-bottom: 0;
    position: relative;
    /*0.5rem*/
  }

  &.show {
    display: block;
    max-height: 250px;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top-color: #eaeaea;
    overflow: visible;
  }

  .for-form-control {
    .react-autosuggest__input {
      @include form-control;
    }
  }

  .form-row {
    .btn {
      height: 37px;
    }
  }
}

@media (max-width: 575px) {
  .sub-header-filter {
    .form-group {
      margin-bottom: 0.5rem;
    }
  }
}

@media (max-width: 575px) {
  .sub-header-filter {
    .form-group {
      .form-label {
        display: none;
      }
    }
  }
}

.filter-tags-sec {
  display: flex;
  align-items: flex-start;
  width: auto;
  max-width: 100%;
  gap: 0.5rem;

  .save-filter-form {
    display: flex;
    flex-wrap: nowrap;

    .form-control {
      padding-right: 0.85rem;
      height: 32.7px;
    }

    .btn {
      white-space: nowrap;
      height: 32.7px;
    }
  }
}

.filter-tags-wrap {
  position: relative;
  padding-right: 0;
  padding-top: 1px;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  .tag {
    margin-bottom: 0;
    margin-right: 0;
    padding-right: 1.5rem;

    i {
      top: 0.55rem;
      right: 7px;
    }
  }

  .tagsmore {
    right: 8px;
    top: 0;
    margin-right: 10px;

    .dropdown-toggle {
      color: #aaaaaa;
      height: 30px;

      i {
        font-size: 1.25rem;
      }

      &:after {
        content: "";
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #ddd;
      }
    }

    &.show .dropdown-toggle {
      background-color: #e0ebf8;
    }

    .dropdown-menu {
      background-color: #e0ebf8;
      border: 0.063rem solid #dce4ec;
      color: #6c8cb5;
      left: 10px !important;

      &:after {
        border-bottom-color: #e0ebf8;
      }

      a {
        font-size: 0.75rem;
        color: #6c8cb5;
        position: relative;
        border-bottom: 0.063rem solid #dce4ec;
        padding: 0.35rem 2.5rem 0.35rem 0.95rem;

        i {
          position: absolute;
          top: 6px;
          right: 7px;
          font-weight: 700;
          font-size: 0.75rem;
        }

        &:active {
          background-color: #f8f9fa;
        }
      }
    }
  }
}

/* Search Edit Column Sec */

.search-edit-col-sec {
  // z-index: 9;
  position: relative;

  .css-2b097c-container {
    min-width: 210px;
  }

  button,
  .btn {
    // height: 36px;
  }

  .form-inline {
    .form-group {
      margin-right: 1rem;

      ~ .form-group {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 599px) {
  .search-edit-col-sec {
    .css-2b097c-container {
      // min-width: 128px;
    }

    .form-group {
      .search-field {
        // width: 128px;
      }
    }
  }
}

/* Layout Sub Header */
.sub-header-layout {
  border-top: 0.063rem solid #eaeaea;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top-color: #eaeaea;
}

.add-more-plan {
  border: 0.063rem solid $success-color;
  height: calc(100% - 0.3rem);
  margin-top: 0.3rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    border: 0.063rem solid $success-color;
    display: inline-flex;
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
    color: $success-color;
  }
}

/* Contact List */

.preview-hover {
  white-space: nowrap;
  position: relative;
  padding-right: 140px !important;

  .btn-preview {
    opacity: 0;
    transition: all 0.3s ease-out;
    height: 28px;
    line-height: 28px;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -14px;
  }
}

.table tr:hover .btn-preview {
  opacity: 1;
}

.lead-score-td i {
  position: relative;
  top: 2px;
}

.checkbox-td {
  width: 30px;
  padding-right: 0 !important;

  & input[type="checkbox"] {
    z-index: 1;
  }
}

.small-avtar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  // background-color: $warning-color;
  background-color: transparent;
  display: inline-block;
  text-align: center;
  line-height: 28px;
  margin-right: 7px;
  color: #fff;
  overflow: hidden;
  vertical-align: middle;

  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.sort-up,
.sort-down {
  width: 0.925rem;
  height: 0.925rem;
  border-radius: 50%;
  // background-color: $border-color;
  display: inline-block;
  margin: 0 0.163rem;
  cursor: pointer;
  position: relative;
  top: 0.093rem;
}

.sort-up {
  margin-left: 0;

  &:before {
    content: "";
    // border-left: 0.188rem solid transparent;
    // border-right: 0.188rem solid transparent;
    // border-bottom: 0.25rem solid #747b86;
    // float: left;
    // margin: 0.31rem 0.27rem;
    background-image: url(../Images/arrow-down-s-line.svg);
    width: 18px;
    height: 18px;
    display: block;
    rotate: 180deg;
  }

  &.active {
    background-color: #747b86;

    &:before {
      border-bottom: 0.25rem solid #fff;
    }
  }
}

.sort-down {
  margin-left: 0;

  &:after {
    content: "";
    // border-left: 0.188rem solid transparent;
    // border-right: 0.188rem solid transparent;
    // border-bottom: 0.25rem solid #747b86;
    // float: left;
    // margin: 0.36rem 0.28rem;
    // border-bottom: none;
    // border-top: 0.25rem solid #747b86;
    background-image: url(../Images/arrow-down-s-line.svg);
    width: 18px;
    height: 18px;
    display: block;
  }

  &.active {
    background-color: #747b86;

    &:after {
      border-top: 0.25rem solid #fff;
    }
  }
}

/**/
.table-footer {
  padding: 0 1.75rem;
  margin-bottom: 1rem;
  margin-top: 0.35rem;

  .css-2b097c-container {
    min-width: 200px;
  }
}

@media (max-width: 991px) {
  .table-footer {
    .pagination {
      justify-content: flex-end !important;
    }
  }
}

@media (max-width: 575px) {
  .table-footer {
    .row {
      flex-direction: column-reverse;

      .form-inline {
        justify-content: center;
        padding-top: 0.25rem;
      }

      .pagination {
        justify-content: center !important;
      }
    }
  }
}

.pagination {
  .page-item {
    padding: 0 0.15rem;
    margin-top: 5px;
  }

  .page-link {
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: $default-color;

    &:focus {
      box-shadow: none;
    }
  }

  .page-item.active .page-link {
    background-color: $light-color;
    color: $primary-color;
  }

  .page-item-prev span,
  .page-item-next span {
    display: none;
  }

  .page-item-prev span.sr-only,
  .page-item-next span.sr-only {
    display: block;
    position: static;
    width: auto;
    height: auto;
    font-weight: 500;
    color: $default-color;
  }

  .page-item-prev .page-link:hover,
  .page-item-next .page-link:hover {
    background-color: transparent;
  }
}

/**/
.custom-control {
  &.custom-checkbox {
    margin-bottom: 5px;

    .custom-control-label {
      padding-left: 3px;

      &:before {
        width: 1.125rem;
        height: 1.125rem;
        top: 0.1rem;
        background-color: #f7f7f7;
        border: $border-color solid 2px;
        border-radius: 0.125rem !important;
      }
    }
  }

  & input {
    z-index: 1;
  }

  &.custom-radio .custom-control-label:before {
    width: 1.125rem;
    height: 1.125rem;
    top: 0.1rem;
    background-color: #f7f7f7;
    border: $border-color solid 0.125rem;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: $primary-color;
    background-color: $primary-color;
  }

  &.custom-checkbox .custom-control-label:after,
  &.custom-radio .custom-control-label:after {
    width: 1.5rem;
    height: 1.5rem;
    top: 0.1rem;
    margin-top: -0.175rem;
    margin-left: -0.17rem;
  }

  &.custom-radio {
    .custom-control-label {
      &:after {
        background-image: none !important;
        background-color: #fff;
        border-radius: 50%;
        margin-left: -3px;
        margin-top: -3px;
        transform: scale(0.35);
      }
    }
  }
}

/* Edit Columns Style */
.edit-columns-modal .modal-header .modal-title {
  font-size: 0.813rem;
  padding: 0.2rem 0;
}

.columns-add-list {
  padding: 25px 0 15px 0;
}

.columns-search-field {
  margin: -1.5rem 0 0 -2rem;
  padding: 1.25rem 2.188rem !important;
  background-color: $light-color;
  border-bottom: 0.063rem solid $border-color;
  border-right: 0.063rem solid $border-color;
  margin-bottom: 0 !important;
}

@media (max-width: 767px) {
  .columns-search-field {
    margin-left: -0.75rem;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

@media (max-width: 575px) {
  .columns-search-field {
    margin-right: -0.75rem;
  }
}

.edit-columns-modal .modal-body {
  padding-bottom: 0;
}

.columns-add-list {
  max-height: 350px;
  overflow: auto;
  border-right: 0.063rem solid #eaeaea;
}

@media (max-width: 575px) {
  .columns-add-list {
    max-height: 250px;
    margin-bottom: 30px;
    border-right: none;
  }
}

.rc-time-picker {
  input {
    height: calc(1.9em + 0.75rem + 2px);
    border-radius: 0.15rem;
    padding: 0.375rem 0.85rem;
  }
}

/***/
.column-list {
  border-radius: 0;
  max-height: 372px;
  overflow: auto;

  .list-group-item {
    font-size: $text-size-xs;
    margin-bottom: 10px;
    background-color: #fff;
    border: 0.063rem solid $light-color;
    position: relative;
    padding: 0.55rem 1.25rem 0.55rem 1.55rem;
    background-image: url(../Images/move.svg);
    background-repeat: no-repeat;
    background-position: left 12px top 11px;
    color: $default-color;
    font-weight: 500;
    border-radius: 0.35rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

    i {
      position: absolute;
      top: 6px;
      right: 12px;
      font-size: 110%;
      cursor: pointer;
      font-weight: 600;
    }
  }
}

@media (max-width: 575px) {
  .column-list {
    max-height: 282px;
    margin-bottom: 30px;
  }
}

/* Import Contacts */
.import-step {
  list-style: none;
  margin: 1rem 0 2.5rem 0;
  width: 100%;
  padding: 0;

  .step-item {
    -ms-flex: 1 1 0;
    flex: 1 1 0;
    margin-top: 0;
    min-height: 1rem;
    position: relative;
    text-align: center;

    &:not(:first-child)::before {
      content: "";
      border-top: 2px solid $success-color;
      left: -50%;
      position: absolute;
      top: 9px;
      width: 100%;
    }

    button {
      color: $success-color;
      display: inline-block;
      padding: 32px 0 10px 0;
      text-decoration: none;
      font-size: 0.9rem;
      font-family: "Public Sans", sans-serif;
      font-weight: 600;
      letter-spacing: 0.5px;
      background: none;
      border: 0;

      &:focus {
        border: none;
        outline: none;
        box-shadow: none;
      }

      &::before {
        background: $success-color;
        border: 0.1rem solid #fff;
        border-radius: 50%;
        content: "\EB7B";
        display: block;
        height: 1.25rem;
        left: 50%;
        position: absolute;
        top: 0;
        z-index: 2;
        transform: translateX(-50%);
        width: 1.25rem;
        z-index: 1;
        font-size: 0.75rem;
        font-family: remixicon;
        font-weight: normal;
        color: #fff;
      }
    }

    &.active {
      button {
        color: $primary-color;

        &::before {
          background: $primary-color;
          border: 0.1rem solid $primary-color;
          box-shadow: 0 0 0 0.4rem $primary-color;
          color: #fff;
          font-size: $text-size-xs;
          line-height: 1rem;
          content: "\EA6E";
        }
      }

      ~ .step-item {
        button {
          color: rgba($default-color, 0.5);

          &::before {
            background: $light-color;
            color: $default-color;
            content: "\EA6E";
          }
        }

        &::before {
          border-top-color: $light-color;
        }
      }

      + .step-item {
        &::before {
          border-top-color: $primary-color;
          border-top-style: dashed;
        }
      }
    }
  }
}

/**/

.drop-file-box {
  @include flex-center;
  justify-content: center;
  height: 9rem;
  border: 0.063rem solid $success-color;
  text-align: center;
  padding: 1rem;

  &.drop-file-box-sm {
    height: 7rem;
  }

  &.drop-file-box-inline {
    height: 4rem;
  }

  .thumb-container {
    display: inline-block;

    .thumb {
      padding: 0;
      margin-bottom: 0;
      height: auto;

      .thumbInner {
        margin-right: 5px;
      }

      .fileDetails {
        margin-left: 0;
      }
    }
  }
}

.map-fields-table {
  border: 0.063rem solid #eaeaea;

  .table-responsive {
    max-height: 260px;
    min-height: 260px;
  }

  .read-only {
    background-color: #eaeaea;
  }
}

.thumb {
  margin-right: 0 !important;
}

/**/

.inset-popup {
  background-color: #fbfbfb;
  border: 0.063rem solid #dee2e6;

  .inset-popup-head {
    border-bottom: 0.063rem solid #dee2e6;
    padding: 15px 20px;
  }

  .inset-popup-body {
    padding: 25px 20px;
  }
}

/*//// CRM Details ////*/
@media (min-width: 1600px) {
  .avtar-main-details-col {
    max-width: 30%;
  }
}

@media (min-width: 1200px) {
  .crm-tab-col {
    // flex: 0 0 73%;
    // max-width: 73%;
  }
}

.crm-main-details {
  .row {
    min-height: 32px;
  }

  .align-items-center {
    span {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.tags-wrap-with-title {
  background-color: $bg-light;
  border-top: 0.063rem solid $border-color;
  padding: 1.063rem 1.063rem 1rem 8rem;
  // margin: 0 -1.75rem -1.75rem -1.75rem;
  position: relative;

  .tags-title {
    position: absolute;
    left: 1.875rem;
    top: 1.313rem;
    display: inline-flex;
    align-items: center;

    i {
      margin-right: 0.5rem;
      font-size: 1.125rem;
    }

    &::after {
      content: "";
      border-right: 0.063rem solid #d3d3d3;
      position: absolute;
      right: -1.25rem;
      top: -0.375rem;
      bottom: -0.375rem;
    }

    &::before {
      content: "";
      border-left: 0.375rem solid #d3d3d3;
      border-top: 0.375rem solid transparent;
      border-bottom: 0.375rem solid transparent;
      position: absolute;
      right: -1.6rem;
      top: 0.295rem;
    }
  }
}

.tags-for-crm-profile {
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #fff;
  margin-left: -1.75rem;
  margin-right: -1.75rem;
  border-top: 0.063rem solid $border-color;
}

.crm-profile-score {
  h2 {
    i {
      line-height: 1;
      position: relative;
      top: 2px;
    }
  }

  .col-4 {
    flex: 0 0 37.333333%;
    max-width: 38.333333%;
  }

  p {
    font-size: $text-size-xs;
    font-weight: 600;
  }
}

/* Engage Links */
.btn-link-group {
  @include flex-center;
  justify-content: space-between;
  overflow: auto;

  .btn {
    display: block;
    padding: 0 2px;
    text-transform: capitalize;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 12px;
    width: 25%;

    i {
      display: block;
      margin: 0;
      font-size: 1.5rem;
    }

    span {
      display: block;
      margin: 5px 0 0 0;
      color: $default-color;
    }
  }
}

.engage-card {
  .btn-link-group {
    .btn {
      max-width: 100%;
      flex: 1 1 100%;

      i {
        font-size: 2rem;
      }

      span {
        /*display: none;*/
      }
    }
  }
}

.product-filter-by-date {
  display: flex;
  flex-direction: column;

  .product-filter-item {
    padding-bottom: 1rem;

    h4 {
      font-size: $text-size-xs;
      margin-bottom: 1rem;
      padding: 0.5rem;
      background-color: rgba($primary-color, 0.12);

      span {
        color: $primary-color;
      }
    }

    h5 {
      font-size: $text-size-xs;
    }

    .h6 {
      font-size: $text-size-xs;
      font-weight: 500;
    }

    .prdct-item {
      border: 0;
      margin-bottom: 0;
    }
  }
}

/* Products Interested In */
.prdct-item {
  border-bottom: 0.063rem solid $border-color;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  img {
    width: 2.188rem;
    border: 0.063rem solid $border-color;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.inrst-prdct-card {
  height: 100%;
}

.engage-card {
  ~ .inrst-prdct-card {
    height: calc(100% - 134px);
  }
}

/* Recent Interaction */
.recnt-prdct-card {
  height: 100%;
}

.recnt-item {
  padding: 0 0 1rem 3.25rem;
  margin-bottom: 1rem;
  position: relative;

  .recnt-item-icon {
    width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem;
    border-radius: 50%;
    background-color: $light-blue-color;
    color: $dark-blue-color;
    border: 0.063rem solid $dark-blue-color;
    display: block;
    font-size: 1.125rem;
    text-align: center;
    position: absolute;
    left: 0;
    top: -0.5rem;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    left: 1.115rem;
    top: 0;
    bottom: -1rem;
    z-index: 0;
    border-left: 0.063rem solid $dark-blue-color;
  }

  &:last-child::after {
    display: none;
  }

  h6 {
    i {
      top: 1.25px;
      position: relative;
    }
  }
}

/* Deal Items  */
.deal-status-item {
  border-bottom: 1px solid $border-color;

  ~ .deal-status-item {
    border-bottom: none;

    .deal-status-step {
      margin-bottom: 1.25rem;
    }
  }
}

/* Deal Status Wizard */
.deal-status-step {
  list-style: none;
  margin: 1rem 0 2.5rem 0;
  padding: 0;
  width: 100%;
  justify-content: space-between;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 1.75rem;
    height: 30px;
    top: 0;
    left: -26px;
    background: #fff;
  }

  .deal-step-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
    min-height: 1rem;
    position: relative;
    text-align: center;

    &:after {
      content: "";
      position: absolute;
      min-width: 400px;
      width: 100%;
      height: 1px;
      background: #537fae;
      top: 1.125rem;
      right: 0;
    }

    &:first-child:after {
      left: 50%;
      right: unset;
    }

    &:last-child:after {
      right: 50%;
    }

    &:first-child:before,
    &:last-child:before {
      content: "";
      position: absolute;
      width: 50%;
      height: 50%;
      background: #fff;
      left: 0;
      top: 0;
      z-index: 1;
    }

    &:last-child:before {
      left: unset;
      right: 0;
    }

    button {
      color: $default-color;
      display: inline-block;
      padding: 3rem 10px 0;
      text-decoration: none;
      font-size: $text-size-xs;
      font-family: "Public Sans", sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      background: none;
      border: 0;

      &:focus {
        border: none;
        outline: none;
        box-shadow: none;
      }

      &::before {
        background: #fff;
        border: 0.063rem solid $dark-blue-color;
        border-radius: 50%;
        content: "\EA6E";
        font-family: remixicon !important;
        display: block;
        height: 2.25rem;
        left: 50%;
        position: absolute;
        top: 0;
        z-index: 2;
        transform: translateX(-50%);
        width: 2.25rem;
        z-index: 1;
        font-size: 1.125rem;
        line-height: 2.25rem;
        font-weight: normal;
        color: $dark-blue-color;
      }
    }

    &.complete {
      button {
        &::before {
          content: "\EB7B";
          background: $light-blue-color;
          border: 0.063rem solid $dark-blue-color;
        }
      }
    }

    &.deal-current-stage {
      button {
        &:before {
          border-color: $color-green;
          background-color: $color-green;
          color: #fff;
        }
      }
    }
  }

  &::before {
    content: "";
    border-top: 0.063rem solid $dark-blue-color;
    left: 2.5rem;
    position: absolute;
    top: 1.125rem;
    right: 2.5rem;
  }
}

/* Conversation Items  */
.cnvrstin-item {
  margin-bottom: 4px;

  .cnvrstin-title {
    @include flex-center;
    background: $light-blue-color;
    color: $dark-blue-color;
    padding: 0.425rem 1.563rem;
    border: 0.063rem solid $border-color;
  }

  .cnvrstin-msg {
    padding: 0.6rem 1.563rem;
    border: 0.063rem solid $border-color;
    background: rgb(250, 252, 255);
    ~ .cnvrstin-msg {
      border-top: none;
    }

    .email-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      position: relative;
      top: 1px;
      font-size: 1.2rem;
      color: $warning-color;
    }

    span {
      vertical-align: middle;
    }

    .cnvrstin-action {
      span {
        display: block;
      }

      .dropdown-menu-right {
        transform: translate(10px, 34px) !important;
      }
    }

    &.msg-close {
      background-color: $bg-light;
    }
  }

  .cnvrstin-editor {
    padding: 1.25rem 1.563rem;
    border: 0.063rem solid $border-color;
    background-color: $bg-light;
  }

  .cnvrstin-msg-reply {
    padding: 0.425rem 1.563rem;
    border: 0.063rem solid $border-color;
    border-top: 0;
  }
}

/* Editor */
.form-group-overlap-label {
  position: relative;
  padding: 0.15rem 0;

  .form-label {
    position: absolute;
    left: 10px;
    top: 12px;
  }

  .right-overlap {
    position: absolute;
    right: 10px;
    top: 12px;

    span {
      margin-left: 1.125rem;
    }
  }

  .form-control {
    border: none;
    border-bottom: 0.063rem solid $border-color;
    background: none;
    padding-left: 5rem;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  textarea.form-control {
    padding-left: 0;
  }
}

.editor-actions {
  .btn {
    padding: 0 0.25rem;
    opacity: 0.8;
    color: $default-color;

    &:hover {
      opacity: 1;
    }

    i {
      font-size: 1.35rem;
    }
  }
}

.files-list {
  .list-group-item {
    position: relative;
    padding-right: 6.5rem;

    .btn {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      position: absolute;
      right: 1rem;
      top: 50%;
      margin-top: -1rem;
    }
  }
}

/* Alert msg in popup */
.alert-in-popup {
  @include flex-center;
  justify-content: center;
  text-align: center;
  min-height: 12rem;

  i {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
  }

  &.alert-in-danger {
    i {
      border: 0.2rem solid $danger-color;
      color: $danger-color;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
    }
  }
}

/* Tabs Header Style */
.tab-header {
  border-bottom: 0.063rem solid #eaeaea;
  padding: 1.25rem 0 0 0;
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .nav-item {
    border-bottom: none;

    .nav-link {
      display: inline-flex;
      padding: 0.65rem 1.25rem;
      color: #91969e;
      border: 0.063rem solid transparent;
      border-bottom: none;
      font-size: 0.95rem;
      font-weight: 600;
      i {
        margin-right: 0.65rem;
        display: block;
        font-size: 1.05rem;
        font-weight: normal;
        line-height: 1.45;
      }

      svg {
        fill: #91969e;
        width: 1rem;
        height: 1.4rem;
        margin-right: 0.5rem;
      }

      &.active {
        background-color: #fff;
        border-radius: 12px 12px 0 0;
        border: 0.063rem solid #eaeaea;
        border-bottom: none;
        bottom: -1px;
        position: relative;
        color: $primary-color;

        svg {
          fill: $primary-color;
        }
      }
    }
  }

  &.tab-header-sm {
    padding: 0;
    // border: 0.063rem solid #eaeaea;
    border-bottom: none;
    margin-bottom: 15px;
    position: relative;
    z-index: 1;
    .nav-item {
      .nav-link {
        padding: 0.5rem 1rem;
        display: flex;
        text-align: center;
        margin-top: -1px;
        border: none;
        border-top: 3px solid transparent;
        border-radius: 10px;

        // text-transform: uppercase;
        &.active {
          background-color: $primary-color;
          color: #fff;
          border: none;
          border-top: 3px solid transparent;
        }

        i {
          // margin-right: 0;
          // display: block;
          // font-size: 1rem;
          // font-weight: normal;
          // line-height: 1.45;
          // margin-right: 8px;
        }
      }
    }
  }
  &.card-inner-tabs {
    margin: -1.95rem -1.75rem;
    padding: 0;
    border-radius: 10px 10px 0 0;
    .nav-item {
      .nav-link {
        align-items: center;
        i {
          line-height: 1.65;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .tab-header.tab-header-sm .nav-item .nav-link {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .crm-tab-col .tab-header {
    background-color: #fff;
    position: sticky;
    top: 0;
  }
  .crm-tab-col .tab-header .nav {
    overflow: visible;
  }
}
.campaign-tab-header {
  position: relative;
  z-index: 2;
}

/* Coupon Style */
.coupon-badge {
  background: #fecb74;
  width: 36px;
  height: 36px;
  position: relative;
  text-align: center;
  color: $default-color;
  display: inline-block;
  margin-right: 1rem;
}

.coupon-badge span {
  position: relative;
  z-index: 2;
  padding: 6px 0 0 0;
  font-size: 1rem;
  display: block;
}

.coupon-badge:before,
.coupon-badge:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 36px;
  width: 36px;
  background: #fecb74;
}

.coupon-badge:before {
  transform: rotate(30deg);
}

.coupon-badge:after {
  transform: rotate(60deg);
}

.coupon-apply {
  display: inline-block;
  padding: 0.4rem 1rem;
  min-width: 120px;
  text-align: center;
  color: $primary-color;
  border: 1px dashed $primary-color;
  position: relative;

  i {
    position: absolute;
    right: 0.5rem;
    top: 7px;
  }
}

/* Upload Images Item */
.uploaded-imgs-sec {
  .col {
    max-width: 20%;
    flex: 0 0 20%;

    .uploaded-item {
      position: relative;
      border-radius: 0.125rem;
      // overflow: hidden;

      span {
        width: 1.875rem;
        height: 1.875rem;
        color: #fff;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 1.2rem;
        cursor: pointer;
        transition: all 0.3s ease-out;
        @include flex-center;
      }

      .default {
        background-color: $success-color;
        right: auto;
        top: auto;
        left: 0;
        bottom: 0;
      }

      .delete {
        background-color: $danger-color;
        opacity: 0;
        visibility: hidden;
      }

      &:hover {
        .delete {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

/* Table Out of Modal */
.table-out-modal {
  margin: 0 -30px;

  .table {
    td,
    th {
      height: 50px;
      padding-left: 0.625rem;
      padding-right: 0.625rem;

      &:first-child {
        padding-left: 37px;
      }

      &:last-child {
        padding-right: 37px;
      }
    }
  }
}

/* Add Coupon Modal */
.selected-products-item {
  display: inline-flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 5px;

  img {
    margin-right: 10px;
  }

  i {
    font-size: 16px;
    margin-left: 10px;
    cursor: pointer;
    font-weight: bold;
  }
}

/* Spinner  */
.spinner-wrap {
  // background-color: rgba(0, 0, 0, 0.5);
  background-color: $extra-light-color;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  @include flex-center;
  justify-content: center;
  z-index: 5;

  .spinner-inner {
    width: 100px;
    height: 100px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    @include flex-center;
    justify-content: center;
  }
}

.spinner-wrap-modal {
  position: relative;
  background-color: transparent;
}

.spinner-wrap-modal-with-bg {
  position: absolute;
  background-color: transparent;
}

/* Alert  */
.alert-outer {
  position: absolute;
  z-index: 9999;
  bottom: 20px;
  left: -100%;
  transition: all 0.3s ease-out;

  &.show {
    left: 20px;
  }
}

.alert {
  background-color: #fff;
  border-color: #eaeaea;
  color: $default-color;
  padding: 0.5rem 0.85rem 0.65rem 0.85rem;
  padding-right: 2.5rem;

  .media {
    // max-width: 800px;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    text-align: left;

    i {
      line-height: 24px;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      font-weight: normal;
      margin-top: 3px;
      text-align: center;
      font-size: 1rem;
    }
  }

  .close {
    padding: 0.6rem 1rem;
    margin-top: 0.1rem;
  }

  &.alert-danger {
    i {
      background-color: $danger-color;
      color: #fff;
    }
  }

  &.alert-success {
    i {
      background-color: $success-color;
      color: #fff;
    }
  }

  &.alert-warning {
    i {
      background-color: $warning-color;
      color: #fff;
    }
  }
}

.simpleHeaderFooter {
  background-color: #fff;
  color: $primary-color;
  border-top: 1px solid #eee;
}

.top-navigation {
  color: $primary-color;
  border-top: 1px solid #eee;
  position: relative;
  background-color: $light-color;

  i {
    font-size: 1.5rem;
  }

  .left-text {
    padding-right: 20px;
    font-size: 12px;
    line-height: 1.3;
    font-weight: 600;
    font-size: 13px;
  }

  .right-content {
    button {
      white-space: nowrap;
    }
  }
}

@media (max-width: 575px) {
  .top-navigation {
    padding-right: 50px !important;
  }

  .top-nav-close {
    position: absolute;
    right: 0;
    top: 50%;
    width: auto;
    margin-top: -13px;
  }
}

/* Pricing Table  */
.plan-card-1 {
  text-align: center;
  // width: 19rem;
  // max-width: 100%;
  border: none;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.06);

  .card-title {
    font-size: 1.375rem;
    position: relative;

    &::after {
      content: "";
      height: 2px;
      width: 34px;
      background-color: $primary-color;
      display: block;
      position: absolute;
      left: 50%;
      bottom: -10px;
      margin-left: -17px;
    }
  }

  h4 {
    font-size: 1.125rem;
  }

  p {
    font-size: $text-size-xs;
  }

  .price {
    color: $warning-color;
    font-size: 3.375rem;
    line-height: 1;

    .sign {
      display: inline-block;
      font-size: 1.375rem;
      margin-right: 0.35rem;
      position: relative;
      top: -1.2875rem;
    }

    .month {
      font-size: 1.125rem;
      margin-left: 0.25rem;
    }
  }

  ul {
    li {
      padding: 3px 0;
      font-size: 1rem;
    }
  }
}

/* Pricing Table 2  */
.plan-card-2 {
  .plan-half-wrap {
    background-color: $light-color;
    margin: -1.75rem -1.75rem 0 -1.75rem;
    padding: 1.75rem;

    .card-title {
      &::after {
        background-color: #fff;
      }
    }

    .btn {
      border: 1px solid #fff;
      background-color: transparent;
    }
  }
}

/* Input With Checkbox Style */
.input-with-checkbox {
  .input-group-text {
    .custom-control-label {
      &::after {
        left: -1.2rem;
      }

      &::before {
        left: -1.15rem;
      }
    }
  }
}

/* Title with count number Style */
.title-with-count {
  .count-circle {
    width: 1.625rem;
    height: 1.625rem;
    background-color: #8dc63f;
    color: #fff;
    text-align: center;
    font-size: 1rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
}

/* Trapezium Tabs Style */
.trapezium-tabs-wrap {
  .nav {
    border-bottom: none;

    .nav-link {
      font-size: 1.125rem;
      font-weight: 600;
      color: $default-color;
      padding: 1.25rem 2.1875rem 1.25rem 2.375rem;

      &.active {
        background-color: $bg-light;
        border: 1px solid #eaeaea;
        border-bottom: none;
        color: $primary-color;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 2.25rem;
        position: relative;

        &::after {
          content: "";
          width: 24px;
          transform: skew(24deg);
          background-color: $bg-light;
          border-top-right-radius: 0.5rem;
          border-right: 1px solid #eaeaea;
          border-top: 1px solid #eaeaea;
          position: absolute;
          right: -8px;
          top: -1px;
          bottom: 0px;
        }
      }
    }
  }

  .tab-content {
    background-color: $bg-light;
    padding: 35px 38px;
    border: 1px solid #eaeaea;
  }
}

@media (max-width: 1630px) {
  :root {
    font-size: 16px;
  }
}

@media (max-width: 1440px) {
  :root {
    font-size: 15px;
  }
}

/* Deal Status Header Bar Style */
.deal-status-head {
  margin-bottom: 40px;
  margin-top: -3.125rem;
  padding-top: 3.125rem;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);

  .deal-head-item {
    margin: 0 0 20px 0;
    border-right: 1px solid #e3e3e3;
    text-align: center;
  }
  .row {
    > div {
      &:last-child {
        .deal-head-item {
          border-right: none;
        }
      }
    }
  }
}

/* Switch Items View Buttons Style */
.switch-items-view {
  .btn {
    padding: 0.5rem 0.65rem;
    height: calc(1.6em + 0.75rem + 2px);

    svg {
      margin: 0;
      fill: white;
    }

    &:hover {
      svg {
        fill: #fff;
      }
    }
  }
}

.deals-grid-wrap {
  overflow: auto;

  // padding-right: 0.5rem;
  // padding-left: 0.5rem;
  .deals-grid-row {
    flex-wrap: inherit;
    margin-right: -0.25rem;
    margin-left: -0.25rem;

    > div {
      padding-right: 0.25rem;
      padding-left: 0.25rem;
      flex: 0 0 16.7%;
      max-width: 16.7%;
      min-width: 230px;
    }
  }
}

.deals-col {
  padding: 0.75rem 0.5rem;
  border-radius: 0.5rem;
  height: 100%;
  background-color: rgba($light-color, 0.6);
}
.sticky-stages {
  .deals-col {
    background-color: #f1f1ff;
  }
}
.deals-col-head {
  .form-control-view {
    .form-control {
      height: 1.5rem;
      font-size: 1rem;
      font-weight: 600;
      padding-bottom: 0.25rem;
    }

    i {
      top: 4px;
    }
  }
}
.jobs-col-item {
  .inner-job-col-item {
    padding: 0.5rem 0.65rem 0.75rem 0.65rem;
  }
}
.deals-col-item {
  padding: 0.5rem 0.65rem 0.75rem 0.65rem;
}
.deals-col-item,
.jobs-col-item {
  background-color: #fff;
  border-radius: 4px;
  // border: 1px solid #e3e3e3;
  // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;

  .more-dropdown {
    .dropdown-menu {
      left: 10px !important;
    }
  }

  .small-avtar {
    width: 25px;
    height: 25px;
    line-height: 22px;
  }
}

.data-droppable-outer-box {
  height: calc(100% - 60px);

  // > div {
  //   height: 100%;
  // }
}

.only-job-grid-wrap {
  .deals-grid-row {
    & > div {
      flex: 0 0 22%;
      max-width: 22%;
    }
  }
  .deals-item-dot-dropdown {
    position: absolute;
    right: 0;
    top: 0;
  }
  .deals-col-item-info {
    position: relative;
    padding-left: 26px;
    margin-bottom: 2px;
    .deals-col-item-info-icon {
      position: absolute;
      left: 0;
      top: -4px;
      font-size: 18px;
      text-decoration: none;
      color: $primary-color;
    }
  }
}

@media (max-width: 1630px) {
  // .deals-col {
  //   padding: 0.75rem 0.75rem;
  // }
  // .deals-col-item {
  //   padding: 0.75rem 0.5rem 1rem 0.5rem;
  // }
}

/* Score Meter Style */
.scoring-header {
  border-top: 0.063rem solid #eaeaea;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-right: 2rem;
  padding-left: 2rem;
}

.scoring-meter-color {
  .input-group-text {
    opacity: 1;

    &:before {
      content: "";
      width: 10px;
      height: 100%;
      margin-right: 10px;
      border-radius: 0.15rem 0 0 0.15rem;
    }
  }

  &.scoring-meter-color-1 {
    .input-group-text {
      &:before {
        background-color: $primary-color;
      }
    }
  }

  &.scoring-meter-color-2 {
    .input-group-text {
      &:before {
        background-color: $warning-color;
      }
    }
  }

  &.scoring-meter-color-3 {
    .input-group-text {
      &:before {
        background-color: $danger-color;
      }
    }
  }
}

/* PROBABILITY Meter Style */
.meter-wrap {
  position: relative;

  .progress-bar-tooltip {
    position: relative;
    height: 0.5rem;

    .progress-bar-tooltip-inner {
      content: "Value Proposition";
      position: absolute;
      right: -53px;
      top: -1.5rem;
      background-color: $default-color;
      color: #fff;
      padding: 0.25rem 0.75rem;
      font-size: 0.65rem;
      border-radius: 0.25rem;
      width: 111px;
      text-align: center;

      &:before {
        content: "";
        position: absolute;
        left: calc(50% - 5px);
        bottom: -5px;
        border-left: 0.35rem solid transparent;
        border-right: 0.35rem solid transparent;
        border-top: 0.35rem solid $default-color;
      }
    }
  }

  .meter-item {
    width: 100%;
    height: 4px;
  }

  .meter-scale {
    display: flex;
    list-style: none;
    width: 100%;
    font-size: 0.75rem;
    padding: 0px 0px 0px 0px;
    margin: 2px 0px 10px 0px;
    border: 0px 0px 0px 0px;
    text-align: center;
    justify-content: space-between;

    li {
      white-space: nowrap;
    }
  }
}

/* PROBABILITY Meter Style */
.selectable-card {
  height: 100%;

  .card-body {
    padding: 0.7rem;
    height: 100%;

    .media {
      font-size: 1.6rem;
      align-items: center;
    }
  }

  svg {
    fill: $primary-color;
  }

  i {
    color: $primary-color;
  }

  &:hover,
  &.active {
    background-color: $light-blue-color;
    color: $dark-blue-color;

    i {
      color: $dark-blue-color !important;
    }

    svg {
      fill: $dark-blue-color !important;
    }
  }
}

.selectable-campaign-goal {
  .nav {
    gap: 20px;

    .col {
      padding: 0;
    }

    svg {
      fill: $primary-color;
    }

    .nav-item {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25rem;
      height: 100%;

      a {
        text-align: center;
        padding: 1rem 0.5rem;
        height: 100%;

        h5 {
          color: #212529;

          &:hover {
            color: $dark-blue-color;
          }
        }

        &:hover,
        &.active {
          background-color: $light-blue-color;
          color: $dark-blue-color;

          i {
            color: $dark-blue-color !important;
          }

          svg {
            fill: $dark-blue-color !important;
          }
        }
      }
    }
  }

  .tab-content {
    .tab-pane {
      padding-top: 2rem;
      margin-top: 2rem;
      border-top: 1px solid #eee;
    }
  }
}

/* Trigger Modal Style */
.trigger-modal-body {
  .selectable-card {
    .card-body {
      svg {
        width: 30px;
        height: auto;
        margin-top: 0.5rem;
        margin-bottom: 0.45rem;
      }
    }
  }
}

.campaign-builder-wrap {
  background-image: url(../Images/campaign-builder-pattern-bg.png);
  padding-bottom: 0;
}

.campaign-builder-sec {
  position: relative;

  .zoom-unit {
    // position: absolute;
    // left: 45px;
    // top: 20px;
    // z-index: 999;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 999;
    padding: 10px 53px;
    width: 50px;
    margin-bottom: -50px;

    > div {
      background-color: $success-color;
      color: #fff;
      width: 28px;
      height: 28px;
      line-height: 24px;
      font-size: 18px;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      i {
        margin-top: 2px;
        display: inline-block;
      }
    }

    .zoom-in {
      margin-bottom: 30px;

      &:after {
        content: "";
        height: 34px;
        width: 2px;
        background-color: $success-color;
        margin: 1px auto;
        display: block;
      }
    }
  }

  .zoom-unit-fixed {
    position: sticky;
    top: 0;
  }
}

.campaign-builder-area {
  position: relative;
  padding: 20px 0;
  height: calc(100vh - 123px);
  overflow: auto;
  width: 100%;
}

.campaign-builder-sec {
  text-align: center;

  .sub-header {
    height: 68px;
  }

  .campaign-stage-box {
    background-color: #fff;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.05);
    border: 1px solid $primary-color;
    border-radius: 0.5rem;
    padding: 20px 30px 30px 30px;
    min-width: 150px;
    max-width: 240px;
    margin: 0 auto;
    text-align: center;
    position: relative;

    i {
      display: block;
      color: $primary-color;
    }

    svg {
      fill: $primary-color;
    }

    &.warning {
      border: 1px solid $color-light-red;

      i {
        color: $color-light-red;
      }

      svg {
        fill: $color-light-red;
      }

      .warning-icon {
        position: absolute;
        left: 0.5rem;
        top: 0.25rem;
        background: none;
        border: none;
        outline: none;
        font-weight: 600;
        font-size: 1rem;
      }
    }

    &.campaign-stage-box-green {
      border: 1px solid $success-color;

      i {
        color: $success-color;
      }
    }

    &.warning {
      border: 1px solid $error-color;

      i {
        color: $error-color;
      }

      svg {
        fill: $error-color;
      }

      .warning-icon {
        position: absolute;
        left: 0.5rem;
        top: 0.25rem;
        background: none;
        border: none;
        outline: none;
        font-weight: 600;
        font-size: 1rem;
      }
    }

    &.active {
      border: 1px solid $warning-color;

      i {
        color: $warning-color;
      }

      svg {
        fill: $warning-color;
      }

      .warning-icon {
        position: absolute;
        left: 0.5rem;
        top: 0.25rem;
        background: none;
        border: none;
        outline: none;
        font-weight: 600;
        font-size: 1rem;
      }
    }

    &.disabled {
      color: $extra-light-color;

      i {
        color: $extra-light-color;
      }

      .warning-icon {
        position: absolute;
        left: 0.5rem;
        top: 0.25rem;
        background: none;
        border: none;
        outline: none;
        font-weight: 600;
        font-size: 1rem;
      }
    }

    &.campaign-start-box-main {
      border-color: $success-color;
    }

    &.campaign-start-box {
      i {
        background-color: $light-green-color;
        color: $success-color;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        line-height: 36px;
        margin: 0 auto 10px auto;
        font-size: 20px;
      }

      &.campaign-false-box {
        i {
          background-color: rgba($danger-color, 0.2);
          color: $danger-color;
        }
      }
    }

    span {
      font-weight: 600;
    }

    .campaign-close {
      position: absolute;
      right: 0.25rem;
      top: 0.25rem;
      background: none;
      border: none;
      outline: none;
      font-weight: 600;
      font-size: 1rem;
    }

    &.campaign-finish-btn {
      width: 100px;
      background-color: $warning-color;
      border-color: $warning-color;
      color: #fff;
      min-width: auto;
      padding: 0.5rem 1rem;

      i {
        color: #fff;
        line-height: 1.2;
      }
    }
  }

  .btn-finish {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 0.5rem;
  }
}

.jointer {
  height: 44px;
  width: 2px;
  background-color: $light-color;
  margin: 0 auto;
  display: block;
  position: relative;
  z-index: 1;

  &:before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $light-color;
    position: absolute;
    left: -3px;
    top: -5px;
  }

  &:after {
    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid $light-color;
    position: absolute;
    left: -4px;
    bottom: 0;
  }

  .btn-circle {
    margin-left: -12px;
    margin-top: 7px;
  }

  &.jointer-with-btn {
    height: 56px;
  }
}

.btn-joint {
  box-shadow: 0 0 0 3px rgba($success-color, 0.2);
  height: 24px;
  white-space: nowrap;
  margin-left: -41px;
  margin-top: 14px;
  padding-left: 7px;
  padding-right: 7px;
  font-size: $text-size-xxs;
}

.jointer-sec {
  .h-jointer {
    border-bottom: 2px solid $light-color;
    border-right: 2px solid $light-color;
    border-radius: 0 0 0.35rem 0;
    width: 50%;
    display: inline-block;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 0.4rem 0 0.25rem 0;
    margin-right: -1px;

    &.h-jointer-r {
      border-right: none;
      border-left: 2px solid $light-color;
      margin-right: 0;
      margin-left: -1px;
      border-radius: 0 0 0 0.35rem;

      &:before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $light-color;
        position: absolute;
        left: -4px;
        top: -5px;
      }
    }
  }

  .jointer {
    margin-top: -2px;

    &:before {
      display: none;
    }
  }
}

.sidebar {
  width: 440px;
  position: absolute;
  right: -440px;
  top: 0;
  bottom: 0;
  background-color: $bg-light;
  border-left: 1px solid $border-color;
  overflow: auto;
  z-index: 9;

  .sidebar-header {
    border-bottom: 1px solid $border-color;
    padding: 1.33rem 2rem;
    height: 68px;
  }

  .sidebar-body {
    padding: 1.33rem 2rem;

    .and-divider {
      margin-left: -2rem;
      margin-right: -2rem;
    }
  }

  .tab-header {
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    .nav-item {
      .nav-link {
        padding: 0.65rem 1.15rem;
      }
    }
  }
}
.agree-check-list {
  border-top: 1px solid $light-color;
  padding: 7px 0;
  &:first-child {
    border-top: none;
  }
}
.edit-campaign-name {
  border: 1px solid transparent;
  padding: 0.2rem 0.5rem;
  padding-right: 25px;
  position: relative;
  max-width: 500px;

  .edit-icon {
    opacity: 0;
    display: none;
    transition: 0.3s all;
    font-size: 1rem;
    margin-left: 0.3rem;
    color: $primary-color;
    position: absolute;
    top: 7px;
    right: 7px;
  }

  &:hover {
    border-color: $border-color;

    .edit-icon {
      opacity: 1;
      display: inline-block;
    }
  }
}

.camp-builder-edit-title {
  width: 340px;
  max-width: 100%;
}

.with-sidebar {
  position: relative;

  .campaign-builder-sec {
    width: calc(100% - 440px);
    position: relative;
  }

  .sidebar {
    right: 0;
  }

  &.webhook-drawer-sidebar {
    .campaign-builder-sec {
      width: calc(100% - 660px);
    }

    .sidebar {
      width: 660px;
    }
  }

  .edit-campaign-name {
    max-width: 300px;
  }
}

/* Workflow Style */
.workflow-area {
  position: relative;
  padding: 20px 50px 0 50px;
  min-width: 300px;
  max-width: 600px;
  margin: -100px auto 0 auto;

  .workflow-stage-box {
    background-color: #fff;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.05);
    border: 1px solid $border-color;
    border-radius: 0.5rem;
    padding: 20px 30px 30px 30px;

    text-align: center;
    position: relative;

    i {
      display: block;
      color: $primary-color;
    }

    svg {
      fill: $primary-color;
    }

    &:after {
      content: "";
      height: 2px;
      width: 50px;
      background-color: $light-color;
      position: absolute;
      left: -50px;
      top: 50%;
      margin-top: -1px;
    }

    &:before {
      content: "";
      width: 2px;
      background-color: $light-color;
      position: absolute;
      left: -50px;
      top: 50%;
      bottom: -48px;
      margin-top: -1px;
    }

    + .workflow-stage-box {
      margin-top: 25px;

      &:before {
        top: 0;
      }
    }

    span {
      font-weight: 600;
    }

    .campaign-close {
      position: absolute;
      right: 0.25rem;
      top: 0.25rem;
      background: none;
      border: none;
      outline: none;
      font-weight: 600;
      font-size: 1rem;
    }
  }

  .add-workflow-action {
    margin-top: 25px;
    position: relative;

    &:after {
      content: "";
      height: 2px;
      width: 50%;
      background-color: $light-color;
      position: absolute;
      left: -50px;
      top: 50%;
      z-index: 0;
      margin-left: 1px;
    }

    button {
      position: relative;
      z-index: 1;
    }
  }
}

.workflow-area-new {
  .workflow-stage-box {
    &:before {
      left: 50%;
      top: auto;
      bottom: -30px;
      height: 30px;
      margin-left: -1px;
    }

    &:after {
      display: none;
    }
  }

  .workflow-stage-sub-box-wrap {
    padding: 30px 50px;
    border: 2px solid $light-color;
    margin-top: 29px;
    border-radius: 6px;

    .workflow-stage-sub-box {
      background-color: #fff;
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.05);
      border: 1px solid $border-color;
      border-radius: 0.5rem;
      padding: 20px 30px 30px 30px;

      text-align: center;
      position: relative;

      + .workflow-stage-sub-box {
        margin-top: 25px;
      }

      i {
        display: block;
        color: $primary-color;
      }

      svg {
        fill: $primary-color;
      }

      span {
        font-weight: 600;
      }

      .campaign-close {
        position: absolute;
        right: 0.25rem;
        top: 0.25rem;
        background: none;
        border: none;
        outline: none;
        font-weight: 600;
        font-size: 1rem;
      }

      &.warning {
        border-color: $warning-color;
        color: $warning-color;

        .text-primary,
        .index-number,
        i {
          color: $warning-color !important;
        }

        .warning-icon {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }

  .add-workflow-action {
    &:after {
      display: none;
    }
  }
}

/* Campaign Details Style */
.campaign-analysts-card {
  .card-body {
    padding-left: 14px;
    padding-right: 14px;

    .analysts-card-outer-col {
      margin-top: -1.75rem;
      margin-bottom: -1.75rem;
      padding: 1.75rem;
      border-left: 1px solid $border-color;
    }
  }
}

/* Campaign Test Sidebar Style */
.campaign-test-step {
  border-right: 2px solid rgba($extra-light-color, 0.6);
  position: relative;
  margin-right: 10px;
  padding-bottom: 1.5rem;
  padding-right: 1rem;

  .campaign-test-icon {
    position: absolute;
    right: -11px;
    top: 0;
    width: 20px;
    height: 20px;
    background-color: $extra-light-color;
    box-shadow: 0 0 0 3px rgba($light-color, 0.2);
    color: #fff;
    border-radius: 50%;
    text-align: center;

    &.complete-test {
      background-color: $success-color;
      box-shadow: 0 0 0 3px rgba($success-color, 0.2);
    }

    &.error-test {
      background-color: $danger-color;
      box-shadow: 0 0 0 3px rgba($danger-color, 0.2);
    }
  }

  .campaign-test-title {
    position: relative;
    padding-left: 30px;

    i {
      position: absolute;
      left: 0;
      top: 2px;
    }

    svg {
      position: absolute;
      left: 0;
      top: -1px;
      width: 23px;
      fill: $primary-color;
      height: auto;
    }
  }

  &.campaign-test-condition {
    .campaign-test-title {
      padding-right: 85px;
    }

    .custom-switch,
    .form-switch {
      position: absolute;
      right: 30px;
      top: 0;
    }
  }
}
/**/
.accordion {
  .accordion-item {
    border-color: $border-color !important;
    border-radius: 0.35rem;
    .accordion-button {
      padding: 0.575rem 1rem;
      font-size: 0.875rem;
      border-radius: 0.35rem;
      border-color: $border-color !important;
      background-color: #eeeef6 !important;
      color: #504f56;
      font-weight: 500;
      i {
        margin-right: 0.45rem;
        font-size: 125%;
      }
      &:after {
        // display: none;
        background-size: 16px auto;
        background-position: center center;
      }
      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  background-color: transparent;
}
/**/
.address-accordion-bs {
  .card {
    border: 0;
    background-color: transparent;
    margin-top: 0;

    .card-header {
      padding: 0;

      .btn {
        min-width: 150px;
        padding: 0;
        justify-content: left;
        font-family: "Public Sans", sans-serif;
        font-weight: 700;
        font-size: 0.688rem;
        margin-bottom: 0.3rem;
        color: #7f8590;
        text-transform: uppercase;
        letter-spacing: 0.03rem;
        // position: relative;
        margin-right: 1rem;

        &:before {
          content: "\ea4e";
          position: absolute;
          font-family: "remixicon" !important;
          right: 0;
          font-size: 14px;
          width: 60px;
          text-align: right;
        }
      }

      .same-ad-chk {
        font-family: "Public Sans", sans-serif;
        font-weight: 600;
        font-size: 0.688rem;
        margin-bottom: 0.3rem;
        color: #7f8590;
        letter-spacing: 0.03rem;
        display: inline-block;
        vertical-align: top;
        text-transform: uppercase;

        input {
          margin-right: 5px;
          vertical-align: middle;
          position: relative;
          margin-top: -3px;
        }
      }
    }

    .active-panel {
      .btn {
        &:before {
          content: "\ea78";
        }
      }
    }

    .collapse {
      padding: 0;
    }

    .card-body {
      padding: 0;
    }
  }
}

.contact-accordion-bs {
  .card {
    .card-header {
      .btn {
        min-width: 150px;
        // padding: 0;
        justify-content: left;
        font-family: "Public Sans", sans-serif;
        font-weight: 700;
        font-size: 0.688rem;
        margin-bottom: 0.3rem;
        color: #7f8590;
        text-transform: uppercase;
        letter-spacing: 0.03rem;
        // position: relative;
        margin-right: 1rem;

        &:before {
          content: "\ea4e";
          position: absolute;
          font-family: "remixicon" !important;
          right: 10px;
          font-size: 18px;
          width: 60px;
          text-align: right;
        }
      }
    }

    .active-panel {
      .btn {
        &:before {
          content: "\ea78";
        }
      }
    }

    .collapse {
      padding: 0;
    }

    .card-body {
      padding: 0;
    }
  }
}

.custom-sm-style {
  .form-group {
    position: relative;

    input {
      padding-left: 30px;
    }

    i {
      font-size: 20px;
      height: 32px !important;
      line-height: 30px;
      color: #3a589e;
    }

    .ri-twitter-fill {
      color: #3b98d3;
    }

    .ri-linkedin-fill {
      color: #0b75b3;
    }

    .ri-skype-fill {
      color: #3e9dd7;
    }
  }
}

.input-group .input-group-text {
  background-color: $light-color !important;
  border-color: $border-color;
  border-radius: 0.35rem 0 0 0.35rem;
}
.input-group .form-control ~ .input-group-text {
  border-radius: 0 0.35rem 0.35rem 0;
}
.react-datepicker-wrapper input.custom-date-input {
  border: 0.063rem solid $border-color;
  border-radius: 0 0.35rem 0.35rem 0;
  font-size: 0.875rem;
  padding-left: 0.85rem;
  padding-right: 0.85rem;
}
.react-datepicker-wrapper input:focus,
.react-datepicker-wrapper .react-datepicker__input-container:focus {
  border-color: $primary-color;
  outline: none;
}
.header-fixed {
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0;
}

.with-sidebar {
  .header-fixed {
    position: fixed;
    width: calc(100% - 440px);
    z-index: 9;
    top: 0;
  }

  &.webhook-drawer-sidebar {
    .header-fixed {
      width: calc(100% - 660px);
    }
  }
}

.sidebar {
  transition: all ease-in 0.3s;

  &.sidebar-fixed {
    position: fixed;
  }
}

// CRM Detail Page (Contact Info)

.score-progress-main {
  height: 18px;
  background-color: #ddd;
  border-radius: 30px;
  overflow: hidden;
  display: table;
  width: 100%;
  margin-top: 8px;

  .score-progress-inner {
    background-color: #36b0bd;
    height: 100%;
    display: table-cell;
    position: relative;
    width: 33.333%;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    vertical-align: middle;

    &::before {
      content: "";
      position: absolute;
      right: 80px;
      background: #36b0bd;
      width: 20px;
      height: 18px;
      transform: rotate(45deg);
    }
  }

  .progress-warm {
    background-color: #58c29a;
  }

  .progress-hot {
    background-color: #eb9e74;

    &::before {
      background: #58c29a;
    }
  }
}

.dropdown {
  .btn-primary {
    background-color: $color-orange !important;
    border-color: $color-orange !important;
    color: #fff;

    &:focus {
      box-shadow: 0 0 0 !important;
    }

    &:hover {
      background-color: transparent !important;
      color: $color-orange;
    }
  }

  .btn-outline-1 {
    border-color: $primary-color !important;
    background-color: $primary-color !important;

    &:hover {
      color: $primary-color !important;
      background-color: transparent !important;
    }
  }

  .btn-outline-2 {
    border-color: #ff5c66 !important;
    background-color: #ff5c66 !important;

    &:hover {
      color: #ff5c66 !important;
      background-color: transparent !important;
    }
  }

  .btn-outline-3 {
    border-color: $warning-color !important;
    background-color: $warning-color !important;

    &:hover {
      color: $warning-color !important;
      background-color: transparent !important;
    }
  }

  .btn-outline-4 {
    border-color: $success-color !important;
    background-color: $success-color !important;

    &:hover {
      color: $success-color !important;
      background-color: transparent !important;
    }
  }
}

.tab-equal-sidebar-1 {
  position: relative;
  // z-index: 999;
  .tab-pane {
    .out-of-container,
    .main-content {
      position: relative;
      z-index: 0;
    }
  }
  .card-body {
    // height: 47rem;
    overflow: auto;
  }
}

.products-interested-fixed-h {
  height: 23.9rem;
  overflow: auto;
}

.recent-interaction-fixed-h {
  height: 23.9rem;
  overflow: auto;
  padding-bottom: 15px;
}

// CRM Detail Page (Contact Info) End

// Default Table

.custom-table {
  .recnt-item-icon {
    width: 38px;
    height: 38px;
    line-height: 38px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    color: $active-orange;
    font-size: 20px;
    border: 1px solid $active-orange;
  }
}

.fix-action-btn {
  width: auto;

  background-color: #fff;
  border: 0;
  padding: 0;
  right: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  max-width: 90px;
}

// Note: CustomTable End

// Default Tab

.custom-tab-bar {
  .nav-item {
    .nav-link {
      background: #eee;
      border-radius: 0;
      margin-right: 10px;
      padding: 7px 30px;
      font-weight: 600;
      color: $default-color;
      font-size: 14px;
    }

    .nav-link.active {
      background-color: $primary-color;
      color: #fff;
    }
  }

  .tab-content {
    margin-top: 20px;
  }
}

// Fire Flam

.fire-flame-bar {
  position: relative;
  border-left: 0.063rem solid #eaeaea;

  img {
    max-width: 100%;
  }

  .fire-flame-inner {
    position: relative;
    max-width: 32px;
    margin: auto;
  }

  .fire-color {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0%;
    width: 100%;
    object-fit: cover;
    object-position: bottom;

    &.fire-blue {
      height: 0%;
    }

    &.fire-yellow {
      height: 0%;
    }

    &.fire-orange {
      height: 0%;
    }
  }
}

// Range Slider

.score-meter-outer {
  margin-bottom: 20px;

  p {
    font-family: "Public Sans", sans-serif;
    font-weight: 700;
    font-size: 0.688rem;
    margin-bottom: 0.3rem;
    color: #7f8590;
    text-transform: uppercase;
    letter-spacing: 0.03rem;
  }

  .score-meter-inner {
    display: inline-block;
    width: 100%;
    border-radius: 30px;
    position: relative;

    .meter-scroll,
    .meter-scroll-2 {
      position: absolute;
      bottom: 0px;
      width: 20px;
      left: 30%;
      padding-bottom: 20px;
      background: #f1f1f1;
      border-radius: 75% 75% 33% 75%;
      transform: rotate(45deg);
      animation: fire 2s linear infinite;
      box-shadow: 0 0 3px #969696;
      z-index: 9;

      &:before {
        content: "";
        position: absolute;
        width: 8px;
        height: 5px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        z-index: 9;
        transform: translate(50%, 50%);
        left: 36%;
        top: 40%;
        transform: rotate(45deg);
      }
    }

    .cards {
      padding: 0;
    }

    .meter-scroll-2 {
      left: 70%;
    }

    .scm-in-layer {
      position: relative;
      width: 33.333%;
      float: left;
      background-color: #4292e4;
      height: 8px;

      &.scm-yellow {
        background-color: #fea919;
      }

      &.scm-red {
        background-color: #f90101;
      }
    }
  }

  .meter-scale {
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: right;
    margin-left: 10px;
    margin-bottom: 0;

    li {
      list-style: none;
      font-size: 10px;
      font-weight: 600;
      list-style: none;
      //width: 15px;
      //background-color: #fda918;
      text-align: left;
    }
  }
}

.fixed-height-basic-details {
  height: 52.1rem;
  overflow-y: hidden;
}

.user-contact-details {
  margin-bottom: 15px;

  p {
    margin-bottom: 0;

    span {
      vertical-align: middle;
      font-size: 20px;

      i {
        font-size: 16px;
      }
    }

    &.phone-modal-bar {
      span {
        cursor: pointer;
      }
    }
  }
}

.fixed-height-recent-interaction {
  height: 480px;
  overflow: auto;
}

.avtar {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  border: 0.063rem solid #e3e3e3;

  .rounded-circle {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
}

.avtar-thumbnail-edit {
  position: relative;
  overflow: hidden;

  .edit-avtar {
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    background: #fda918d9;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    opacity: 0;
    visibility: hidden;
    transition: all ease-in 0.2s;
  }

  &:hover {
    .edit-avtar {
      opacity: 1;
      visibility: visible;
    }
  }
}

.deal-status-card {
  height: 49.4rem;
  overflow: auto;

  .deal-status-card-pip-line {
    overflow-x: auto;
    white-space: nowrap;
  }
}

.fixed-height-tags {
  // height: 7.6rem;
  height: 9.7rem;
  overflow: auto;
}

.sticky-tab {
  position: sticky;
  top: -27px;
  z-index: 1;
}

.add-create-new-field {
  border: 0.063rem solid #e3e3e3;
  padding: 10px 15px;
  background: #fff;
}

.create-new-label {
  font-size: 10px;
  float: right;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  top: 3px;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .company-detail-tab-col {
    flex: 0 0 73%;
    max-width: 73%;
  }
}

.company-detail-tab-col {
  .company-details-tab-inner {
    background-color: #fff;

    .card-body {
      height: 678px;
      overflow: auto;
    }
  }
}

.appointment-date {
  .rdatez {
    display: flex;
    flex: 1 1 auto;
    width: 1%;
  }
}

.user-contact-detail-icon {
  .contact-detail-icon-col {
    display: inline-block;
    text-align: center;
    position: relative;

    span {
      display: inline-block;
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 50px;
      margin: 0 5px;
      color: $color-blue;
      font-size: 1.1rem;
      // border: 1px solid $color-blue;
      background-color: rgba($color-blue, 0.08);
      cursor: pointer;
    }
  }
}

// .crm-tab-col {
//   .tab-header {
//     .nav-item .nav-link.active {
//       background-color: #fff;
//     }
//   }
// }

.deal-status-step {
  white-space: nowrap;
  overflow: auto;
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    // background-color: $primary-color;
    background-color: #d1d6db;
    border-radius: 10px;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d1d6db;
  border-radius: 10px;
}

@media (max-width: 1240px) {
  .tags-for-crm-profile {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .tab-header {
    &.tab-header-sm {
      .nav-item {
        .nav-link {
          // padding: 0.5rem 1.4rem 1rem 1.4rem;
        }
      }
    }
  }
}

.table-wrap.table-activity-tab {
  .table-responsive {
    min-height: auto;
    max-height: auto;
  }
}

nav.navbar-dark {
  .navbar-nav {
    .nav-link {
      font-size: 0.9rem;
      font-family: "Public Sans", sans-serif;
      font-weight: 500;

      &:hover {
        color: $color-orange;
      }
    }
  }
}

.navbar {
  .nav-item {
    .dropdown-menu {
      min-width: 240px;
      padding: 0.6rem;
      // border-radius: 0 0 10px 10px;
      box-shadow: 0 5px 7px 0 #00000021;
      border: 0;
      font-family: "Public Sans", sans-serif;
      z-index: 999;

      a {
        color: $default-color;
        font-weight: 600;
        font-size: 0.975rem;
        border-radius: 8px;
        padding: 0.5rem 1rem;
        margin: 0.1rem;

        i {
          margin-right: 1rem;
          font-size: 1.1rem;
          color: $primary-color;
        }

        &.active,
        &:hover,
        &:focus {
          background-color: $light-color;
        }
      }
    }
  }
}

.add-filter-and-or {
  select {
    height: 100%;
    font-size: 0.7rem;
    font-weight: 600;
    margin-top: 0px;
    min-width: 70px;
  }
}

// Note: CustomDropdown Menu

.more-dropdown.table-custom-dropdown {
  .dropdown-toggle {
    white-space: unset;
  }

  .dropdown-menu {
    // left: -40px !important;
    // top: -30px !important;
    right: 40px !important;
    top: 0px !important;
    transform: none !important;
    left: unset !important;

    &::before,
    &::after {
      border-top: 9px solid transparent;
      border-left: 9px solid #777777;
      border-bottom: 9px solid transparent;
      margin-left: 0;
      right: -18px;
      top: 8px;
    }

    &::after {
      border-left: 9px solid #fff;
      margin-left: 2px;
      top: 8px;
      right: -18px;
    }

    &[x-placement^="top-end"] {
      top: unset !important;
      bottom: -7px !important;

      &::before,
      &::after {
        top: unset;
        bottom: 8px;
      }

      &::after {
        top: unset;
        bottom: 10px;
      }
    }
  }
}

// New Dashboard Design

.chat-modal-main {
  .modal-dialog {
    width: 100%;
    max-width: 730px;
  }

  .chat-icon-header {
    button {
      display: inline-block !important;
      margin-left: 1rem;
      margin-top: 7px;
      z-index: 99;
      position: relative;

      &:focus {
        outline: 0;
      }
    }
  }
}

// Chat Box
.chat-message-log {
  .chat-tab-body {
    padding: 1.5rem;
    border-top: 1px solid $border-color;
    background-color: #edf5f8;
    max-height: 33rem !important;
    overflow: unset !important;
    padding-right: 15px !important;
  }

  .chat-tab-body > div {
    overflow-y: auto;
    /* Enable scrolling for each section */
    padding-right: 10px;
    /* Add a bit of padding to prevent content from being hidden under the scrollbar */
  }

  /* CSS */
  .thread-list-container {
    overflow-y: auto;
    /* Ensure a separate scrollbar for threads list */
    // height: 100%; /* Adjust the height as needed */
    height: 30rem;
  }

  .message-list-container {
    overflow-y: auto;
    height: 30rem;
    scroll-behavior: smooth;

    /* Enable smooth scrolling */
    .centered-box {
      width: 100%;
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .message-list {
      margin-right: 10px;
    }
  }

  .up-arrow-container {
    font-size: 1.6rem;
    padding: 0px 0 0 9px;
    margin: 0;
    text-align: center;
    position: absolute;
    bottom: 10px;
    right: 30px;
    color: #3e7cff;

    i {
      cursor: pointer;
    }
  }

  .thread {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #ccc;

    h4 {
      font-size: 0.8rem;
      margin-bottom: 0;
    }

    em {
      font-size: 0.6rem;
    }

    p {
      font-size: 0.8rem;
      margin-bottom: 0px;
    }

    .thread-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .thread:hover {
    background-color: white;
  }

  .selected-thread {
    background-color: white;
  }
}

.chat-main-tab {
  .chat-content-inner {
    border: 1px solid #eee;

    .chat-header-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 1.5rem;

      .chat-user-bar {
        padding-left: 50px;
        position: relative;

        figure {
          width: 40px;
          height: 40px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(-0%, -50%);
          margin-bottom: 0;

          img {
            width: 100%;
            height: 40px;
            border-radius: 50%;
            border: 2px solid $border-color;
          }
        }

        h4 {
          margin-bottom: 0;
          font-weight: 700;
        }

        p {
          margin-bottom: 0;

          i {
            font-size: 0.9rem;
            vertical-align: middle;
            color: $whatsapp-color;
          }

          span {
            font-size: 0.7rem;
            font-weight: 600;
          }
        }
      }

      .chat-icon-header {
        i {
          vertical-align: middle;
          color: $whatsapp-color;
          position: relative;
          top: -1px;
          font-size: 1.6rem;
        }

        span {
          font-weight: 600;
          font-size: 0.9rem;
        }

        button {
          display: none;
        }
      }
    }

    .chat-tab-body {
      padding: 1.5rem;
      border-top: 1px solid $border-color;
      background-color: #edf5f8;
      max-height: 28rem;
      overflow: auto;

      .chat-room {
        .message {
          margin-bottom: 30px;
          display: flex;
          flex-direction: column;

          &.message-left {
            align-items: flex-start;

            .action-on-message {
              max-width: 100%;
              position: absolute;
              right: -40px;
              top: 35%;
              width: auto;
              height: 40px;
              border: 0;
              display: none;

              i {
                padding: 5px;
                background-color: #ccc;
                border-radius: 50%;
                color: #fff;
                margin-left: 5px;
              }

              i.active,
              i:hover {
                background-color: $warning-color;
              }
            }
          }

          &.message-right {
            align-items: flex-end;

            .action-on-message {
              max-width: 100%;
              position: absolute;
              left: -40px;
              top: 45%;
              width: auto;
              height: 40px;
              border: 0;
              display: none;

              i {
                padding: 5px;
                background-color: #ccc;
                border-radius: 50%;
                color: #fff;
                margin-right: 5px;
              }

              i.active,
              i:hover {
                background-color: $warning-color;
              }
            }
          }

          &.message-left:hover .action-on-message,
          &.message-right:hover .action-on-message {
            display: block;
          }

          .message-inner {
            max-width: 80%;
            padding-left: 55px;
            position: relative;

            .avtar {
              position: absolute;
              left: 0;
              top: 0;
              width: 40px;
              height: 40px;
              border: 0;

              img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 2px solid #fff;
              }
            }

            .message-text {
              background: #d7e4f4;
              padding: 0.6rem 0.7rem 1.5rem 0.7rem;
              font-size: $text-size-xs;
              border-radius: 0 5px 5px 5px;
              position: relative;
              min-width: 250px;

              a {
                color: #fff;
              }

              &::before {
                content: "";
                position: absolute;
                left: -10px;
                top: 0;
                border-top: 0px solid transparent;
                border-bottom: 8px solid transparent;
                border-right: 10px solid #d7e4f4;
              }

              span {
                position: absolute;
                bottom: 3px;
                right: 10px;
                font-size: 0.7rem;
                text-transform: uppercase;
                font-weight: 700;
                color: #48607c;

                i {
                  margin-right: 5px;
                  font-size: 0.8rem;
                }
              }

              span.message-reaction {
                background-color: #fff;
                border-radius: 25px;
                position: absolute;
                bottom: -20px;
                right: 10px;
                font-size: 20px;
                width: 28px;
                height: 28px;
                z-index: 9999;
                cursor: default;
              }

              .photo-cover {
                background-color: #d8e2ee;
                border-radius: 5px;

                a {
                  text-decoration: none;
                  color: #000;
                }

                .border.p-1 {
                  border: 0 !important;
                  padding: 0 !important;
                }
              }

              video,
              audio,
              img {
                max-width: 300px;
              }
            }
          }

          &.message-right {
            .message-inner {
              padding-left: 0;
              padding-right: 55px;

              .avtar {
                left: unset;
                right: 0;
              }

              .message-text {
                background: #ddfaca;
                border-radius: 5px 0 5px 5px;
                color: #000;

                &::before {
                  left: unset;
                  right: -10px;
                  border-right: 0;
                  border-left: 10px solid #ddfaca;
                }

                span {
                  color: #000;
                }
              }
            }
          }
        }
      }

      .whatsapp-footer-text-color {
        color: #9c9898;
      }
    }

    .chat-footer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      padding: 1rem 2rem;
      border-top: none;
      background-color: #fff;

      .emoji-attech-icon {
        font-weight: normal;
        font-size: 1.2rem;
        width: 5rem;

        span + span {
          margin: 0 5px;
          cursor: pointer;
        }
      }

      .message-typing-area {
        width: calc(100% - 5rem);

        .form-group {
          margin-bottom: 0;
          display: flex;

          textarea.form-control {
            min-height: 34px;
            max-height: 72px;
            border-radius: 30px;
            padding-left: 1rem;
            padding-right: 1rem;
            resize: none;
            overflow: hidden;
          }

          .btn.sendButton {
            width: 35px;
            height: 32px;
            line-height: 32px;
            border-radius: 50%;
            padding: 0;
            text-align: center;
            background: $whatsapp-color;
            border-color: $whatsapp-color;
            margin-left: 0.6rem;
            display: flex;
          }
        }
      }

      .media-action-icons {
        i {
          font-size: 24px;
          padding-top: 5px;
        }

        i:hover {
          color: #1890ff !important;
        }
      }
    }
  }
}

.text-message-chat-main {
  .chat-user-bar {
    p {
      i {
        color: $color-blue !important;
      }
    }
  }

  .chat-icon-header {
    i {
      color: $color-blue !important;
    }
  }

  .message-right {
    .message-inner {
      .message-text {
        background-color: $color-blue !important;

        &::before {
          border-left-color: $color-blue !important;
        }
      }
    }
  }

  .chat-footer {
    .message-typing-area {
      width: 100% !important;

      .form-group {
        .btn {
          background-color: $color-blue !important;
          border-color: $color-blue !important;
        }
      }
    }
  }
}

.call-log-popup {
  .call-log-content {
    border-radius: 0;
    border: 1px solid #eee;

    .chat-header-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 1.5rem;
      background-color: #fff;

      .chat-user-bar {
        padding-left: 50px;
        position: relative;

        figure {
          width: 40px;
          height: 40px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(-0%, -50%);
          margin-bottom: 0;

          img {
            width: 100%;
            height: 40px;
            border-radius: 50%;
            border: 2px solid #ddd;
          }
        }

        h4 {
          margin-bottom: 0;
          font-weight: 700;
        }

        p {
          margin-bottom: 0;

          i {
            font-size: 0.9rem;
            vertical-align: middle;
            color: $color-blue;
          }

          span {
            font-size: 0.7rem;
            font-weight: 600;
          }
        }
      }

      .chat-icon-header {
        i {
          vertical-align: middle;
          color: $color-blue;
          position: relative;
          top: -1px;
          font-size: 1.6rem;
        }

        span {
          font-weight: 600;
          font-size: 0.9rem;
        }

        button {
          display: none;
        }
      }
    }

    .call-log-body {
      background-color: #edf5f8;
      padding: 2rem;

      .call-note-main {
        width: 100%;
        // max-width: 580px;
        margin: auto;

        .call-note-inner {
          background: #fff;
          padding: 1.5rem;
          border-radius: 8px;
          margin-bottom: 30px;

          .audio-bar {
            margin-top: 15px;

            audio {
              width: 100%;
            }
          }

          h5 {
            span {
              width: 26px;
              height: 26px;
              display: inline-block;
              background: #1d8aff;
              color: #fff;
              font-size: 0.9rem;
              line-height: 26px;
              vertical-align: middle;
              text-align: center;
              border-radius: 50%;
              margin-right: 8px;
            }
          }

          .call-date-time {
            font-size: 0.75rem;
            color: #666;
          }

          h4 {
            margin-top: 1.3rem;
            font-size: 0.9rem;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.coming-soon-overlay-main {
  position: relative;

  .coming-soon-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(#f9f9f9, 0.6);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;

    .coming-soon-inner {
      text-align: center;
      background-color: $primary-color;
      padding: 1rem 2rem;
      border-radius: 10px;

      .icon {
        width: 60px;
        height: 60px;
        display: inline-block;
        background: #fff;
        border-radius: 50%;
      }

      svg {
        width: 30px;
        color: $primary-color;
      }

      h3 {
        margin-top: 10px;
        margin-bottom: 0;
        color: #fff;
        font-size: 1rem;
      }
    }
  }
}

.payment-gateway-coming-soon {
  .modal-body {
    .coming-soon-overlay-main {
      min-height: 400px;
      position: unset;
    }
  }
}

.booking-offering-table {
  table {
    tbody {
      td {
        height: auto;

        &:last-child {
          .remove-offering {
            font-size: 1.5rem;
            cursor: pointer;
            color: $color-light-red;
          }
        }

        .b-offering-inner {
          min-height: 70px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          white-space: normal;
        }
      }
    }
  }

  .b-offering-th {
    min-width: 300px;
    white-space: normal;
  }

  .b-offering-td {
    position: sticky;
    padding-left: 105px;

    figure {
      width: 55px;
      height: 55px;
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translate(-0%, -50%);

      img {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        border: 2px solid #eee;
      }
    }

    h5 {
      font-weight: 600;
      margin-bottom: 0.1rem;
    }

    p {
      font-size: 0.8rem;
      margin-bottom: 0;
    }
  }
}

.custom-switch,
.form-switch {
  display: inline-block;
  margin-left: 0.3rem;

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: $color-green;
    background-color: $color-green;
  }
}

.custom-switch .custom-control-label::before {
  // left: -2.25rem;
  // width: 4rem;
  // border-radius: 0;
  // height: 1.6rem;
  // border: 2px solid #6E5DC6;
}

.add-offering-modal {
  .modal-content {
    margin-top: 20%;
  }
}

.default-title-bar h3 {
  font-size: 1.2rem;
  font-weight: 700;
}

.Choose-offering-trapezium-tab {
  .table-wrap {
    border: 0;

    .table-responsive {
      min-height: auto;
      max-height: auto;
    }
  }
}

// Responsive Preview
.responsive-mobile,
.responsive-tablet {
  position: relative;
  max-width: 270px;
  width: 100%;
  height: 520px;
  background-color: #fff;
  margin: 2rem auto;
  border-radius: 30px;
  border: 13px solid;
  border-top: 43px solid;
  border-bottom: 40px solid;
  border-color: #ddd;

  // max-width: 100%;
  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #fff;
    top: -26px;
    left: 26px;
    border-radius: 50%;
  }

  &::after {
    content: "";
    position: absolute;
    width: 42px;
    height: 6px;
    background-color: #fff;
    top: -24px;
    left: calc(50% - 21px);
    border-radius: 2px;
  }

  .responsive-mobile-button {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: -35px;
    left: calc(50% - 15px);
    background: #ddd;
    border-radius: 50%;
    box-shadow: inset 0 0 6px #7b7b7b;
    // &::before {
    // 	content: '';
    // 	position: absolute;
    // 	width: 12px;
    // 	height: 12px;
    // 	top: calc(50% - 6px);
    // 	left: calc(50% - 6px);
    // 	border: 1px solid #fff;
    // }
  }

  .responsive-inner-content {
    overflow: auto;
    height: 99.5%;
  }
}

.responsive-tablet {
  max-width: 560px;
  width: 100%;
  height: 680px;

  &::before {
    left: 40px;
  }

  .responsive-inner-content {
    height: 99.5%;
  }
}

.responsive-desktop {
  position: relative;
  max-width: 970px;
  width: 100%;
  height: 500px;
  background-color: #fff;
  margin: 2rem auto 200px;
  border-radius: 30px;
  border: 13px solid;
  border-bottom: 40px solid;
  border-color: #ddd;

  &::before {
    content: "";
    position: absolute;
    width: 26px;
    height: 65px;
    background-color: #ddd;
    bottom: -100px;
    left: calc(50% - 13px);
    border-radius: 0;
  }

  &::after {
    content: "";
    position: absolute;
    width: 200px;
    height: 30px;
    background-color: #ddd;
    bottom: -124px;
    left: calc(50% - 100px);
    border-radius: 50%;
  }

  .responsive-desktop-content {
    width: 100%;
    height: 99.5%;
    overflow: auto;
  }
}

.select-layout-div {
  .lt1-card {
    height: calc(100% - 78px);
  }
}

/****/
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.plan-details-fixed {
  position: fixed;
  right: 40px;
  top: 100px;
}

.broadcast-header-action.broadcast-header-action {
  margin-top: -24px !important;
  padding-left: 5px;
  width: 60%;
  left: 100px;
  box-shadow: none;
}

// Booking Preferences

.set-availability-tabs-wrap {
  border: 1px solid #ddd;
  padding: 1rem 1rem;

  .import-step {
    margin-bottom: 0;
  }
}

.booking-preferences-iner {
  margin-top: 1.5rem;

  h3 {
    font-size: 1.3rem;
    font-weight: 600;
  }

  h6 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1.2rem;
  }

  .select-date-range-inner {
    min-height: 8rem;
    margin-bottom: 2rem;
    text-align: center;

    .DayPicker-wrapper {
      &:focus {
        outline: none;
      }

      .DayPicker-Months {
        flex-direction: row;
        flex-wrap: unset;
      }

      .DayPicker-Month {
        max-width: 100%;
        width: 500px;

        .DayPicker-Day {
          font-size: 1rem;
        }
      }
    }
  }

  .DayPicker {
    display: block;

    .DayPicker-Month {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .select-time.booking-pref-inner-col {
    margin-top: 1rem;
  }

  .rc-time-picker {
    flex: 1 1 auto;

    input {
      outline: none;
    }
  }

  .form-to {
    font-weight: 600;
    display: block;
    text-align: center;
    line-height: 36px;
    font-size: 1rem;
  }

  .choose-set-offs {
    margin-top: 1rem;
    position: relative;

    h5 {
      font-weight: 600;
    }

    .set-reccuring-day-off,
    .set-particular-day-off {
      margin-bottom: 2rem;
    }

    .set-reccuring-off-inner {
      border-radius: 5px;
      padding: 0.6rem;
      padding-left: 1.5rem;
      margin-top: 1.1rem;
      box-shadow: 0 0 3px rgba($default-color, 0.2);

      &.active {
        background-color: $primary-color;
      }

      &.custom-checkbox-1 {
        padding-left: 3rem;

        input:checked + label:before {
          background-color: $primary-color;
          border-color: #fff;
        }

        input:checked + label h4,
        input:checked + label p {
          color: #fff;
        }
      }

      &.custom-radio-1 {
        padding-left: 3rem;

        // input:checked + label:before {
        // 	border-color: #fff;
        // }

        // input:checked + label:after {
        // 	background-color: #fff;
        // }
        // input:checked + label h4,
        // input:checked + label p {
        // 	color: #fff;
        // }
      }

      label {
        margin-bottom: 0;
      }

      h4 {
        font-weight: 600;
        font-size: 1rem;
        margin-bottom: 0;
      }

      p {
        margin-bottom: 0;
        font-size: 0.75rem;
      }

      input:checked + .custom-checkbox-1 {
        background-color: #000;
      }
    }
  }

  .set-off-calendar {
    height: 100%;
    margin-top: 1rem;
    padding: 1rem;
    box-shadow: 0 0 3px rgba($default-color, 0.2);
    text-align: center;
    height: calc(100% - 1rem);

    .DayPicker-Month {
      width: 350px;
    }

    .DayPicker-Day--disabled {
      color: #fff;
    }

    .DayPicker-Day--disabled:before {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      background-color: #d93026;
      border-radius: 50%;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .DayPicker-Day--outside:before {
      background-color: transparent;
    }
  }
}

.Range
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
    .DayPicker-Day--end
  ):not(.DayPicker-Day--outside) {
  background-color: #5facf0 !important;
  color: #000;
}

.Range
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--selected
  ):not(.DayPicker-Day--outside):hover {
  background-color: #5facf0 !important;
}

.Range .DayPicker-Day {
  border-radius: 0 !important;
}

.pic-date-from-popup {
  position: absolute;
  left: 0;
  top: 0rem;
  width: 100%;
  max-width: 100%;
  background: #fff;
  z-index: 3;
  box-shadow: 0 0 5px rgba($bg-dark, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;

    .custom-dates-picker {
      .DayPicker-Day.DayPicker-Day--selected:before {
        content: "";
        position: absolute;
        width: 30px;
        height: 30px;
        background-color: #d93026;
        border-radius: 50%;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .pic-date-popup-inner {
    .close-btn {
      position: absolute;
      right: 0;
      top: 0;
      padding: 1rem;
      text-align: center;
      font-size: 1.2rem;
      opacity: 0.8;
      cursor: pointer;
      z-index: 99;
    }

    .DayPicker {
      width: 100%;
    }

    .DayPicker-Months {
      width: 100%;
    }

    .DayPicker-Month {
      width: 100%;
    }
  }
}

// Checkout

.booking-summary-main {
  font-size: 0.938rem;

  //   background: #f1f1f1;
  //   padding: 1.5rem;
  .booking-summary-inner {
    h3 {
      text-transform: uppercase;
      font-weight: 700;
      position: relative;
      padding-bottom: 20px;

      &::before {
        content: "";
        position: absolute;
        bottom: 7px;
        left: 0;
        width: 60px;
        height: 3px;
        background-color: $primary-color;
      }
    }

    .checkout-detail-row {
      margin-bottom: 1.4rem;

      h5 {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.8rem;
        margin-top: 0.8rem;
      }

      .checkout-purchase-detail {
        margin-bottom: 2rem;

        .purchase-detail-box {
          background: #f7f8f8;
          padding: 1rem 1.2rem;
          height: 100%;

          .purchase-detail-box hr {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
          }

          .purchase-detail-list {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            padding: 5px 0;

            .prches-linst-inner {
              flex: 1 1 auto;
              width: auto;
              max-width: 100%;
            }

            .purchase-pricing {
              flex-shrink: 0;
              text-align: right;
            }
          }

          .coupon-code-bar {
            height: 100%;
            display: flex;
            // align-items: start;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;

            form {
              display: flex;
              justify-content: space-between;
              flex-direction: row;
              padding: 5px 0;
              width: 100%;

              .form-group {
                flex: 1 1 auto;
                width: auto;
                max-width: 100%;
                margin-bottom: 0;
                padding-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.switch-field {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  label {
    background-color: #e4e4e4;
    color: $primary-color;
    line-height: 1;
    text-align: center;
    padding: 8px 16px;
    border: 1px solid $primary-color;
    transition: all 0.1s ease-in-out;
    text-transform: uppercase;
    margin-bottom: 0;
    background-color: #fff;

    &:hover {
      cursor: pointer;
    }
  }
}

.switch-field input:checked + label {
  background-color: $primary-color;
  box-shadow: none;
  color: #ffffff;
}

.set-off-calendar,
.pic-date-popup-inner {
  .DayPicker-Day--disabled {
    color: #fff;
  }

  .DayPicker-Day--disabled:before {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: #d93026;
    border-radius: 50%;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .DayPicker-Day--outside:before {
    background-color: transparent;
  }
}

// .custom-table-2 {
//   border: 1px solid #ededed;
//   box-shadow: 0px 0px 20px 2px #eaeaea;
//   padding: 1rem;

//   table {
//     tr:nth-child(odd) {
//       background-color: #f6f6f6;
//     }

//     .custom-control.custom-checkbox .custom-control-label:before {
//       border: 1px solid #ddd;
//     }
//   }
// }

.what-you-offer-wizard {
  &.wizard-buttons {
    padding: 0 0 2rem;
  }

  .wizard-tabs {
    padding: 2rem 0;
  }

  .wizard-tab-item {
    padding: 2rem 1rem;
    box-shadow: 0 0 13px 0 #dae3ecba;
    height: 100%;

    .wizard-icon {
      background-color: transparent;
      border: 0;
      width: auto;
      height: auto;
      margin-top: 0;
      margin-bottom: 1rem;

      img {
        margin-top: 0;
      }
    }

    .wizard-tab-title {
      color: $default-color;
    }
  }
}

.setting-wizard-personal-details {
  &.wizard-personal-details {
    padding: 2rem 50px;
  }
}

.custom-field-drawer {
  .inset-popup {
    border: 0;
    background-color: transparent;

    .inset-popup-body {
      padding: 0;

      .btn {
        font-size: $text-size-xxs;
        padding: 0.552rem 1.15rem;
      }
    }
  }
}

// Responsive

@media (max-width: 1024px) {
  .dashboard-setup {
    padding-top: 1rem;
  }
}

// Crm Permission Switch

.switch-permission {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 24px;

  input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $primary-color;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &::before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  input:checked + .slider {
    background-color: $color-green;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(46px);
    -ms-transform: translateX(46px);
    transform: translateX(46px);
  }

  /*------ ADDED CSS ---------*/

  .on,
  .off {
    color: white;
    position: absolute;
    transform: translate(-0%, -50%);
    top: 50%;
    right: 1rem;
    font-size: $text-size-xxs;
    font-weight: 600;
    text-align: right;
  }

  .on {
    display: none;
    text-align: left;
    right: unset;
    left: 1rem;
  }

  input:checked + .slider .on {
    display: block;
  }

  input:checked + .slider .off {
    display: none;
  }

  /*--------- END --------*/

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

// Note: CustomField Not Found

.field-not-found {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  flex-direction: column;

  img {
    max-width: 70px;
    margin-bottom: 0.5rem;
  }
}

.choose-company-modal {
  .login-signup-form-style-2 {
    padding: 0;
    min-height: auto;
  }
}

.campaign-compare-main {
  margin-top: 4rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 1.8rem;
    right: 1.8rem;
    top: 0;
    bottom: 0;
    border: 1px solid $border-color;
    user-select: none;
    pointer-events: none;
  }

  .compare-top-bar-main {
    margin-top: 2rem;

    .compare-top-box {
      text-align: center;
      padding: 0.8rem 0.3rem 0.5rem;
      border-radius: 4px;
      position: relative;

      span {
        font-size: 1.5rem;
        color: $primary-color;
      }

      p {
        font-weight: 600;
        font-size: $text-size-xs;
        margin-bottom: 0.5rem;
      }

      h4 {
        font-weight: 700;
      }
    }
  }

  .campaign-title-main {
    position: fixed;
    top: -0px;
    left: 1.8rem;
    right: 1.8rem;
    transition: all 0.5s ease-in-out;
    z-index: 2;
    opacity: 0;
    visibility: hidden;

    &.campaign-title-fixed {
      top: 0;
      opacity: 1;
      visibility: visible;
    }

    .campaign-title-inner {
      background-color: $primary-color;
      font-size: 1.2rem;
      font-weight: 600;
      text-align: center;
      color: #fff;
      padding: 0.6rem 1rem;
      text-transform: uppercase;

      &.campaign-b-bg {
        background-color: #e8091f;
      }
    }
  }

  .compare-top-bar-main-new {
    padding: 0 1rem;

    .campaign-ab-top {
      display: table;
      width: 100%;
      flex-flow: row;
      border: 1px solid #e3e3e3;
      max-width: 94%;
      margin: auto;
      padding: 1rem 0.8rem;
      position: relative;
      border-radius: 5px;
      margin-top: -1.7rem;
      background-color: #fff;

      > div {
        display: table-cell;
        width: 33.333%;
        text-align: center;

        &:first-child,
        &:last-child {
          color: $primary-color;
        }
      }

      h4 {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 1.2rem;
        margin-bottom: 0;

        &.color-red {
          color: #e8091f !important;
        }
      }
    }

    ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        flex: 0 0 33.333%;
        max-width: 33.333%;
        padding: 0.5rem 1rem;
        border-top: 1px solid $border-color;
        text-align: center;
        font-weight: 600;
        font-family: $font-montserrat;
        font-size: 1.1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &:nth-child(2) {
          border-right: 2px solid #e8091f;
          border-left: 2px solid #e8091f;
          font-size: 1.1rem;
          background-color: $bg-light;

          span {
            text-align: left;
            width: 246px;
            display: inline-block;
          }
        }

        i {
          vertical-align: middle;
          color: $warning-color;
          font-size: 2rem;
          margin-right: 0.7rem;
        }
      }

      &:nth-child(odd) {
        li:nth-child(2) {
          border-left-color: $primary-color;
          border-right-color: $primary-color;
        }
      }

      &:first-child {
        li {
          border-top: 0px;
        }
      }
    }
  }

  .campaign-compare-graph-area {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      width: 1px;
      background-color: $border-color;
      z-index: 2;
    }

    > .row {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
        border-top: 1px solid $border-color;
        z-index: 1;
      }
    }

    .card {
      height: 100%;
      border: 0;
      border-radius: 0;
      overflow-x: hidden;

      .card-body {
        height: 100%;
        min-height: 300px;

        .no-data-found-span {
          display: flex;
          height: 300px;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .campaign-a-mobile,
    .campaign-b-mobile {
      background: $primary-color;
      color: #fff;
      padding: 0.5rem 1rem;
      text-align: center;
    }

    .campaign-b-mobile {
      background-color: #e8091f;
    }
  }

  .css-2b097c-container {
    max-width: 180px;
    min-width: 180px;
  }

  .graph-outer-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .email-graph-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
}

.campaign-analytics-main {
  margin-top: 2rem;
  padding-bottom: 2rem;

  .analytics-top-bar-main {
    margin-top: 2rem;

    .analytics-top-box {
      padding: 1rem 1rem;
      padding-left: 80px;
      box-shadow: 0 0 4px 0 #dae3ecba;
      border-radius: 4px;
      position: relative;

      span {
        font-size: 1.5rem;
        color: $primary-color;
        width: 45px;
        height: 45px;
        line-height: 45px;
        background: $light-blue-color;
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translate(-0%, -50%);
        text-align: center;
        border-radius: 5px;
      }

      p {
        font-weight: 700;
        font-size: $text-size-xs;
        margin-bottom: 0.5rem;
      }

      h4 {
        font-weight: 700;
        font-size: 1.8rem;
        margin-bottom: 0;
      }
    }
  }

  .css-2b097c-container {
    max-width: 180px;
    min-width: 180px;
  }

  .graph-outer-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .email-graph-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .switcher-with-Dropdown {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 12;

    .form-group {
      margin-bottom: 0;
    }

    label {
      margin-bottom: 0;
    }
  }

  .stats-per-email-graph {
    position: relative;

    .form-group {
      position: absolute;
      right: 96px;
      top: 15px;
      z-index: 2;
    }
  }

  .table {
    td {
      span {
        display: inline-block;
      }

      .btn-link {
        float: left;
        font-size: 0.96rem;
        font-weight: 500;
      }
    }
  }
}

.switch-button-1 {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 28px;

  input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $primary-color;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &::before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 6px;
      bottom: 5px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  input:checked + .slider {
    background-color: $color-green;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(50px);
    -ms-transform: translateX(50px);
    transform: translateX(50px);
  }

  /*------ ADDED CSS ---------*/

  .on,
  .off {
    color: white;
    position: absolute;
    transform: translate(-0%, -50%);
    top: 50%;
    right: 1rem;
    font-size: $text-size-xxs;
    font-weight: 600;
    text-align: right;
  }

  .on {
    display: none;
    text-align: left;
    right: unset;
    left: 1rem;
  }

  .off {
    padding-right: 0.5rem;
  }

  input:checked + .slider .on {
    display: block;
  }

  input:checked + .slider .off {
    display: none;
  }

  /*--------- END --------*/

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

// Email Templates

.emailer-template-sidebar {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100vh;
  overflow: auto;
}

.emailer-template-tabs {
  gap: 5px;
  justify-content: left;
  margin-bottom: 30px;
  margin-top: 10px;

  .nav-link {
    color: $primary-color;
    border: 1px solid $primary-color;
    font-weight: 600;
    padding: 5px 10px;
    font-size: $text-size-xs;

    &.active,
    &:hover {
      background: $primary-color;
      color: #fff;
    }
  }
}

.emailer-templates-bar {
  height: 100vh;
  overflow: auto;
}

// Email Template View for builder

.email-industry-type {
  .btn {
    border: 1px solid #eee;
    margin-left: -1px;
    border-radius: 0;
    color: $default-color;
    font-weight: 600;
    text-transform: none;
    font-size: $text-size-xs;

    &:hover,
    &:focus {
      background-color: $color-green;
      border-color: $color-green;
      color: #fff;
    }
  }
}

.view-email-templates {
  margin-top: 1.5rem;
  padding: 0.5rem;
  box-shadow: 0px 9px 30px #33539114;
  border-radius: 15px;

  figure {
    position: relative;

    img {
      width: 100%;
      max-height: 300px;
      object-fit: cover;
      object-position: top;
      border-radius: 0;
      border: 1px solid #f9f9f9;
    }

    .template-preview {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      background: #112c4ac9;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      padding: 1rem;
      opacity: 0;
      visibility: hidden;
      transition: all ease-in-out 0.3s;

      span {
        background: #fff;
        padding: 0.2rem 0.7rem 0;
        font-size: 1.3rem;
        border-radius: 5px;
        color: $primary-color;
        cursor: pointer;
      }
    }
  }

  h4 {
    font-size: $text-size-sm;
    text-align: center;
  }

  &.active {
    border: 2px solid $primary-color;
  }

  &:hover {
    figure {
      .template-preview {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.active-row {
  .action-col-sticky {
    z-index: 2;
  }

  .preview-hover {
    .btn-preview {
      opacity: 1;
    }
  }
}

.active-sub-table {
  > td {
    padding: 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border: 2px solid #ccc;
      border-top: 0;
    }

    thead {
      th {
        z-index: 1;
        background-color: transparent;
      }
    }
  }
}

@media (min-width: 1200px) {
  .active-sub-table {
    table {
      th,
      td {
        &:last-child {
          padding-right: 60px;
        }
      }
    }
  }
}

// .email-editor-outer {
// 	.tox-edit-area {
// 		// margin-bottom: 120px;
// 	}
// }

// .email-signature-editor {
// 	position: absolute;
// 	bottom: 40px;
// 	left: 20px;
// }

// .mce-content-body {
// 	position: relative;
// }

.horizontal-scroll-tab {
  .nav {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    .nav-item {
      display: inline-block;
      white-space: nowrap;
    }
  }
}

.modal {
  .login-signup-form {
    .form-inner-main-bar {
      min-height: auto;
    }

    .form-field-bar {
      box-shadow: 0 0 0;
    }
  }
}

.sidebar-sticky {
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;

  .make-me-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
    // padding: 0 15px;
  }
}

@media (max-width: 767px) {
  .sidebar-sticky {
    position: relative;

    .make-me-sticky {
      padding: 0;
    }
  }
}

.quote-heading-row {
  th {
    &:nth-child(2) {
      width: 270px;
    }

    &:nth-child(3) {
      min-width: 200px;
    }
  }
}

.overlay-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: hsla(0, 0%, 97.6%, 0.8);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  .overlay-popup-inner {
    text-align: center;
    background-color: $primary-color;
    padding: 3rem 2rem;
    max-width: 500px;
    color: #fff;
    border-radius: 10px;

    h3 {
      font-size: 1.7rem;
      font-weight: 700;
    }

    p {
      font-size: 1rem;
      font-weight: 600;
    }
  }
}

.results-not-found {
  text-align: center;
  padding: 2rem 1rem;

  img {
    max-width: 150px;
  }

  p {
    margin-top: 1rem;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0;
  }
}

.note-box {
  background-color: rgba($color-red, 0.08);
  padding: 0.5rem 1rem;
  border-left: 2px solid $color-red;
  font-family: $font-montserrat;
  font-weight: 500;
  margin-bottom: 1rem;
}

.note-information-integrate-mail {
  .note-information-inner {
    border: 1px solid $border-color;
    padding: 1rem;

    ul {
      padding-left: 1.5rem;
      margin: 0;

      li {
        font-size: $text-size-xs;
      }
    }

    ~ .note-information-inner {
      margin-top: 1rem;
    }
  }
}

.notification-main-bar {
  .card-body {
    padding: 0;
  }

  .name-with-avtar {
    width: auto;

    p.text-ellipsis {
      min-width: 400px;
      -webkit-line-clamp: 2;
    }
  }

  .custom-table {
    td {
      &:first-child {
        max-width: unset;
      }
    }
  }
}

@media (max-width: 767px) {
  body {
    .notification-main-bar {
      padding: 1rem 0;

      .card {
        .card-body {
          padding: 0;
        }
      }
    }
  }
}

/* Calling Bar Style */
.calling-bar {
  background-color: #8dc63f;
  color: #fff;
  padding: 5px 0;
  font-size: 18px;
}

.calling-bar .controls .btn {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0 5px;
}

.calling-bar .controls .btn:hover {
  opacity: 0.6;
}

.calling-bar .controls i {
  font-size: 20px;
}

.calling-bar .controls .btn-warning {
  background-color: $color-orange;
}

.calling-bar .controls .btn-warning:hover,
.calling-bar .controls .btn-warning:focus {
  color: #fff;
}

.calling-bar .controls i.ri-phone-fill {
  transform: rotate(135deg);
}

@media (max-width: 575px) {
  .calling-bar {
    font-size: 15px;
  }
}

/**/
.out-of-container {
  .container-fluid {
    padding: 0;
  }
}

#templatePreview p {
  margin-bottom: 0px;
}

.cnvrstin-msg iframe p {
  margin-bottom: 0px;
}

.special-small-grayish-black {
  font-size: 0.9rem;
}

.PhoneInput input {
  border-color: transparent;
  outline: none;
}

.verify-email {
  background-image: url("../Images/Final_dashboard.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 768px;
  height: 100%;
}

/* Buy Credits */
.buy-credit-wrap .special-small-grayish-black {
  line-height: 1.6;
}

.smallCreditSelect {
  min-width: 220px;
  width: 220px;
  position: relative;
  top: -5px;
  margin-right: 10px;
}

.countryFlag {
  .dropdown-menu.show {
    display: block;
    overflow: auto;
    height: 150px;
  }

  button.btn-light {
    padding: 5px 10px;
    border: none;
    background-color: transparent;
    border: 1px solid transparent;
  }

  button.btn-light:hover {
    border-bottom: 1px solid #e3e3e3;
  }

  button.btn-outline-primary {
    padding: 5px 10px;
    border-color: #e3e3e3;
  }

  button.btn-outline-primary-:hover {
    border-color: $primary-color;
  }
}

/*** CSS SUPPORT OLD BOOTSTRAP**/
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.tooltip {
  pointer-events: none;
  position: absolute;

  .text-danger {
    color: #fff !important;
  }
}

.form-group {
  margin-bottom: 1rem;
  position: relative;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.btn-link {
  text-decoration: none !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.add-quote-item-table-outer tbody {
  border-color: #dee2e6;
}

.form-check-input {
  border-color: $border-color !important;
}

.form-check-input:checked {
  background-color: $success-color;
  border-color: $success-color !important;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba($success-color, 0.25);
}

.form-switch {
  .form-check-input:checked {
    background-color: $success-color;
    border-color: $success-color !important;
  }
}

.form-check:not(.form-switch) {
  [type="checkbox"].form-check-input {
    position: relative;
    z-index: 1;
    display: block;
    color: #656769;
    width: 1rem;
    height: 1rem;
    top: 0.3rem;
    border: 1px solid $light-color;
    border-radius: 0.125rem !important;
    -webkit-print-color-adjust: exact;
    opacity: 0.8;
    margin-top: -2px;
    margin-right: 5px;
  }
}

// .btn-primary,
// .btn-primary:hover,
// .btn-primary:focus,
// .btn-primary:active,
// .btn-outline-primary:hover,
// .btn-outline-primary:focus {
//   color: #fff;
//   background-color: $primary-color;
//   border-color: $primary-color;
// }

.font-weight-light {
  font-weight: 300 !important;
}

.active-reaction {
  border-radius: 50%;
  background-color: #292929;
}

/******/
.google-btn-wrapper {
  display: block;
  position: relative;
  top: 0px;
  left: 0px;
  height: 44px;
  width: 250px;
  border: 0px;
  margin: 0 auto;

  .btn-container {
    color: #202124;
    direction: ltr;
    font-family: "Roboto", arial, sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
    margin: 0;
    overflow: hidden;
    -webkit-text-size-adjust: 100%;

    .btn-outer {
      -webkit-border-radius: 4px;
      border-radius: 4px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition:
        background-color 0.218s,
        border-color 0.218s;
      transition:
        background-color 0.218s,
        border-color 0.218s;
      background-color: #fff;
      background-image: none;
      border: 1px solid #dadce0;
      color: #3c4043;
      cursor: pointer;
      font-size: 14px;
      height: 40px;
      letter-spacing: 0.25px;
      outline: none;
      overflow: hidden;
      padding: 0 8px;
      position: relative;
      text-align: left;
      vertical-align: middle;
      white-space: nowrap;
      width: auto;

      .btn-inner {
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-flex-direction: row;
        flex-direction: row;
        justify-content: space-between;
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        height: 100%;
        position: relative;
        width: 100%;

        .btn-img {
          height: 18px;
          margin-right: 24px;
          min-width: 18px;
          width: 18px;
        }

        .btn-label {
          -webkit-flex-grow: 1;
          flex-grow: 1;
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  }
}

.outlook-btn-wrapper {
  display: block;
  position: relative;
  top: 0px;
  left: 0px;
  height: 41px;
  width: 250px;
  border: 0px;
  margin: 0 auto;

  .btn-container {
    display: block;
    position: relative;
    overflow: hidden;

    .btn-outer {
      padding: 0 12px;
      position: relative;
      text-align: left;
      vertical-align: middle;
      white-space: nowrap;
      letter-spacing: 0.25px;
      outline: none;
      // font-family: "Segoe UI Regular";
      // border: 1px solid #8c8c8c;
      // color: #5e5e5e;
      // font-weight: 600;
      font-size: 14px;
      -webkit-transition:
        background-color 0.218s,
        border-color 0.218s;
      transition:
        background-color 0.218s,
        border-color 0.218s;
      font-family: "Roboto", arial, sans-serif;
      border: 1px solid #dadce0;
      color: #3c4043;
      font-weight: 500;
      text-transform: uppercase;
      direction: ltr;
      -webkit-font-smoothing: antialiased;
      margin: 0;
      overflow: hidden;
      -webkit-text-size-adjust: 100%;
      background-color: #fff;
      background-image: none;
      cursor: pointer;
      height: 41px;
      width: 250px;
      border-radius: 0.35rem;

      .btn-inner {
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-flex-direction: row;
        flex-direction: row;
        justify-content: space-between;
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        height: 100%;
        position: relative;
        width: 100%;

        .btn-img {
          height: 18px;
          margin-right: 12px;
          min-width: 18px;
          width: 18px;
        }

        .btn-label {
          -webkit-flex-grow: 1;
          flex-grow: 1;
          cursor: pointer;
        }
      }
    }
  }
}

.booking-availability-type {
  border: 1px solid rgb(222, 226, 230);
  cursor: pointer;

  &:hover {
    border-color: $primary-color;
  }
}

.booking_summary:empty:before {
  content: attr(data-placeholder);
  color: gray;
}

.sticky-stages {
  // background: #fbfafa;
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  z-index: 3;
}

.alert-dismissible {
  .btn-close {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 2;
    padding: 0.25rem 1rem;
  }
}

.custom-modal {
  max-height: 85vh;
  overflow-y: auto;
  /* Set the desired height using vh (viewport height) */
}

.statusCol {
  text-overflow: unset !important;
  max-width: 210px !important;
}

.deals-col-default {
  border-bottom: 3px solid $extra-light-color;
}

.deals-col-primary {
  border-bottom: 3px solid $primary-color;
}

.deals-col-success {
  border-bottom: 3px solid $success-color;
}

.deals-col-warning {
  border-bottom: 3px solid $warning-color;
}

.dancing-script-signature {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.marck-script-regular {
  font-family: "Marck Script", cursive;
  font-weight: 400;
  font-style: normal;
}

.hurricane-regular {
  font-family: "Hurricane", cursive;
  font-weight: 400;
  font-style: normal;
}

.show-hide-comp-container {
  overflow: hidden;
  max-height: 0;
  transition: max-height 1s ease-out;
}

.show-hide-comp-container.active {
  max-height: 500px;
}

.chatbot-setup {
  .form-check-label {
    font-family: "Public Sans", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.3rem;
    color: #212529;
    letter-spacing: 0.03rem;
  }
}

.chatbot-step-checkboxes .form-check-input {
  margin-top: 2px !important;
}

.dynamic-field-labels .form-check-label {
  font-size: 0.83rem;
}

.top-box {
  height: 300px;
  /* Set the maximum height */
  overflow-y: auto;
  /* Enable vertical scroll if content exceeds the height */
}

.rounded-corner-box {
  border-radius: 10px;
  /* Adjust the radius as per your preference */
  margin-bottom: 8px;
  /* Add some spacing between the entries */
  width: 100%;
  /* Set the width to 100% to fill the container */
}

.custom-table .table-responsive {
  min-height: 150px;
}

/* styles.css */
.custom-list-group {
  border: 1px solid #ced4da;
  border-radius: 8px;
  overflow: hidden;
}

.custom-list-item {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  color: #495057;
  border-bottom: 1px solid #ced4da;
  padding: 10px;
}

.custom-list-item:last-child {
  border-bottom: none;
}

.custom-list-item:hover {
  background-color: #e2e6ea;
}

.item-text {
  flex-grow: 1;
}

.remove-icon {
  cursor: pointer;
}

/* Create Job */
.job-type-agree-sec hr {
  border-top: 0.063rem solid rgba(0, 0, 0, 0.1);
  opacity: 1;
}

.job-agree-sec .row > div:first-child {
  padding-right: 50px;
}

.job-agree-sec .row > div:last-child {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 50px;
}

/**/
.job-type-agree-sec .form-check-inline:last-child {
  // margin-right: 0;
}

.setup-reminder-unit {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 2px;
}

.setup-reminder-unit span,
.setup-reminder-unit .form-check {
  margin-bottom: 10px;
}

.setup-reminder-unit .form-control {
  width: 50px;
  margin-bottom: 10px;
}

.setup-reminder-unit input[type="date"] {
  width: 150px;
}

.setup-reminder-unit .form-select {
  width: 120px;
  margin-bottom: 10px;
}

.billing-cycle-unit .form-control {
  width: 150px;
}

.total-jobs-unit {
  border-radius: 6px;
  border: 1px solid $border-color;
  padding: 15px;
}

.total-jobs-unit .row > div {
  border-right: 1px solid $border-color;
}

.total-jobs-unit .row > div:last-child {
  border-right: none;
}

.total-jobs-unit-title {
  font-family: "Public Sans", sans-serif;
  font-weight: 600;
  font-size: 0.825rem;
  margin: 0;
}

.total-jobs-unit-count {
  margin: 0;
}

/**/
.estmt-info-table {
  background-color: #fff;
  border: 1px solid $light-color;
  width: 100%;
  margin-top: 25px;
  padding: 1rem 1.25rem;
  border-radius: 10px;
}
.estmt-info-table-item {
  padding: 8px 3px;
  border-bottom: 1px solid $light-color;
  font-size: 95%;
}
.estmt-info-table .row > div:nth-child(7) .estmt-info-table-item,
.estmt-info-table .row > div:nth-child(8) .estmt-info-table-item,
.estmt-info-table .row > div:nth-child(9) .estmt-info-table-item {
  border-bottom: none;
}
.estmt-info-table-title {
  display: inline-block;
  opacity: 0.8;
  width: 150px;
}
/**/
.accordion {
  .job-info-unit {
    border: 0.063rem solid #eaeaea !important;
    padding: 15px 20px;
    .job-info-unit-head {
      margin-bottom: 0;

      .accordion-button {
        background-color: transparent !important;
        background-image: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM5 9H15V11H5V9Z" fill="%236E5DC6"/></svg>');
        background-size: 20px auto;
        background-repeat: no-repeat;
        background-position: right 5px center;
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
        border-radius: 0;
        border-bottom: none !important;
        box-shadow: none;
        h4 {
          // color: $default-color;
          color: $primary-color;
        }
        i {
          font-size: 125%;
          // color: $default-color;
          color: $primary-color;
        }
        &.collapsed {
          background-image: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 9V5H11V9H15V11H11V15H9V11H5V9H9ZM10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" fill="%236E5DC6"/></svg>');
        }
      }
    }
    .accordion-body {
      padding: 0;
      border-top: 1px solid $light-color !important;
      .job-info-item {
        padding: 8px 3px;
        border-bottom: 1px solid $light-color;
        font-size: 95%;
        display: flex;

        .job-info-item-title {
          display: inline-block;
          opacity: 0.7;
          margin-right: 15px;
        }
      }
    }
  }
}
.job-info-unit .row > div:last-child .job-info-item {
  border-bottom: none;
}
/**/
.repeater-title {
  position: absolute;
  top: -1.75rem;
}

.repeater-wrapper + .repeater-wrapper .repeater-title {
  display: none;
}
@media (max-width: 767px) {
  .repeater-title {
    position: static;
  }
}
/********** Range Input Styles **********/
/*Range Reset*/
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: rgba($success-color, 0.25);
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -12px; /* Centers thumb on the track */

  /*custom styles*/
  background-color: $success-color;
  height: 2rem;
  width: 1rem;
}
input[type="range"]::-webkit-slider-thumb:active {
  background-color: $success-color;
}
input[type="range"]::-webkit-slider-thumb:focus {
  background-color: $success-color;
}
input[type="range"]:focus::-webkit-slider-thumb {
  border: 1px solid rgba($success-color, 0.25);
  outline: 3px solid rgba($success-color, 0.25);
  outline-offset: 0.125rem;
}

/******** Firefox styles ********/
/* slider track */
input[type="range"]::-moz-range-track {
  background-color: rgba($success-color, 0.25);
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0; /*Removes default border-radius that FF applies*/

  /*custom styles*/
  background-color: $success-color;
  height: 2rem;
  width: 1rem;
}

input[type="range"]:focus::-moz-range-thumb {
  border: 1px solid rgba($success-color, 0.25);
  outline: 3px solid rgba($success-color, 0.25);
  outline-offset: 0.125rem;
}
/**/
.react-datepicker-popper {
  .react-datepicker {
    border: 1px solid $border-color;
    box-shadow: 0 0.125rem 0.775rem rgba(0, 0, 0, 0.05);
    font-family: "Public Sans", sans-serif;
    border-radius: 0.75rem;
    .react-datepicker__header {
      background-color: $light-color;
      border-bottom: 1px solid $border-color;
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
    }
    .react-datepicker__day--selected {
      background-color: $primary-color;
    }
  }
  .react-datepicker__triangle {
    stroke: $border-color !important;
  }
}
/***/
.engage-conver-head {
  height: 60px;
  padding: 0.5rem 1.25rem;
  border-bottom: 1px solid $border-color;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.engage-conver-list-head {
  border-right: 1px solid $border-color;
}
.engage-conver-body {
  padding: 1rem;
  background-color: $light-color;
  height: calc(100% - 60px);
}
/**/
.btn-group {
  .btn {
    padding: 0.515rem 1rem;
  }
}
/**/
.dispch-jobs-list-head {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dispch-jobs-list-sec {
  margin: 10px 0 0 0;
  overflow-x: scroll;
  white-space: nowrap;
  height: 200px;
  border-radius: 0;
}
.dispch-jobs-list-outer {
  margin-left: -15px;
  margin-right: -15px;
}
.dispch-jobs-list-item {
  display: inline-block;
  width: 400px;
  padding: 0 15px;
  vertical-align: top;
  border-right: 1px solid $light-color;
  height: 100%;
  font-size: 0.85rem;
}
.dispch-jobs-info-wrap {
  display: flex;
  margin-bottom: 5px;
}
.dispch-jobs-info-item {
  display: flex;
}
.dispch-jobs-info-wrap .dispch-jobs-info-item {
  width: 50%;
}
.dispch-jobs-info-title {
  opacity: 0.75;
}
.dispch-jobs-info-desc {
  font-weight: 500;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}
.dispch-jobs-detail-wrap {
  border-top: 1px solid $light-color;
  margin-top: 10px;
  padding-top: 10px !important;
}
/**/
.dispch-jobs-actions {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  min-height: 400px;
}
/**/
.gantt-chart-head {
  padding: 15px;
}
.date-navigation {
  display: flex;
  border: 1px solid #e7e7ff;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
}
.date-navigation .date-nextprev {
  padding: 6px 10px;
  cursor: pointer;
}
.date-navigation .date-nextprev.date-prev {
  border-right: 1px solid $light-color;
}
.date-navigation .date-today {
  padding: 6px 10px;
  white-space: nowrap;
  font-size: 0.85rem;
}
.date-navigation .date-nextprev.date-next {
  border-left: 1px solid $light-color;
}
/**/
.selected-jobs-units-wrap {
  display: flex;
  height: 100%;
}
.calendar-card .selected-jobs-unit {
  max-height: 100vh;
  // max-height: 550px;
}
.selected-jobs-unit {
  max-width: 350px;
  flex: 0 0 350px;
  border-right: 1px solid $light-color;
  max-height: 450px;
}
.selected-jobs-unit-head {
  padding: 15px;
  border-bottom: 1px solid $light-color;
}
.selected-jobs-unit-body {
  padding: 15px;
  height: calc(100% - 70px);
  overflow-y: auto;
}
/**/
.selected-jobs-actions {
  max-width: calc(100% - 350px);
  flex: 0 0 calc(100% - 350px);
}
/**/
.job-selected-item {
  border: 1px solid $light-color;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 15px;
  font-size: 0.85rem;
  background-color: #fff;
}
.job-selected-item h6 {
  font-weight: 500;
  margin: 0;
}
.job-selected-item ul li {
  display: flex;
  padding: 4px 8px;
  justify-content: space-between;
  border-bottom: 1px solid $light-color;
}
.job-selected-item ul li:first-child {
  border-bottom: none;
}
.job-selected-item ul li:last-child {
  border-bottom: none;
}
/**/
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
  height: 69px;
  display: flex;
  align-items: center;
  padding: 0 15px;
}
.fc-toolbar-chunk {
  display: flex;
  font-size: 0.85rem;
  align-items: center;
  border-radius: 8px;
}
.fc-toolbar-chunk:nth-child(2) {
  border: 1px solid #e7e7ff;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 0;
}
.fc-toolbar-chunk .fc-button {
  background-color: transparent;
  color: $default-color;
  border: none;
  padding: 7px 10px;
}
.fc-toolbar-chunk .fc-button.fc-prev-button {
  border-right: 1px solid #e7e7ff;
  font-size: 10px;
  padding-top: 11px;
  padding-bottom: 11px;
}
.fc-toolbar-chunk .fc-button.fc-next-button {
  border-left: 1px solid #e7e7ff;
  font-size: 10px;
  padding-top: 11px;
  padding-bottom: 11px;
}
.fc-toolbar-chunk .fc-toolbar-title {
  padding: 0 25px;
  font-size: 0.85rem;
}
.fc a {
  color: $default-color;
  text-decoration: none;
}
.fc .fc-col-header-cell a {
  font-size: 0.85rem;
  padding: 10px 20px;
}
.fc .fc-daygrid-day-frame a {
  font-size: 0.85rem;
}
.fc .fc-button-group {
  border: 1px solid $primary-color;
  border-radius: 8px;
}
.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: $primary-color;
}
.fc .fc-button-primary:hover {
  background-color: $primary-color;
}
/**/
.schedule-popup-table-wrap {
  max-height: 300px;
  overflow: auto;
  margin-bottom: 20px;
  border-bottom: 1px solid $light-color;
}
/**/
.slot-tooltip {
  font-size: 0.875rem;
}

.fs-m-1 {
  margin-bottom: 10px;
}
/**/
.input-group {
  .input-group-text {
    flex: 0 0 auto;
    width: auto;
  }
  .react-datepicker-wrapper {
    flex: 1 0 0%;
    max-width: 100%;
    width: 100%;
  }
}
.dropdown-in-dropdown-unit .css-yki5jv-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group .css-1h2tfia-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.select-error .css-1gk81xk-control,
.select-error .custom-date-input,
.select-error .input-group-text,
.select-error .css-1h2tfia-control {
  border-color: #dc3545 !important;
}

.select-error .react-datepicker__input-container input {
  border: 1px solid #dc3545 !important;
}
/**/
.receipt-after-upload {
  position: relative;
  .receipt-upload-remove {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    padding: 5px;
    position: absolute;
    top: -17px;
    right: -17px;
    cursor: pointer;
  }
  img {
    width: 100%;
    max-height: 100px;
  }
}

.crm-tab-col {
  position: relative;
  z-index: 99;
  .card {
    position: relative;
    z-index: 0;
  }
}
.more-tab-dropdown {
  position: relative;
  z-index: 99;
  .dropdown-toggle {
    &::after {
      margin-top: 10px;
    }
  }
  .dropdown-menu {
    position: absolute;
    z-index: 99;
    padding: 10px;
    box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.1294117647);
    border: 0;
    .dropdown-item {
      padding: 5px 10px;
      font-weight: 500;
      font-size: 0.85rem;
      border-radius: 8px;
      color: $default-color;
      &:hover {
        background-color: $light-color;
      }
      &.active {
        background-color: $primary-color;
        color: #fff;
      }
      i {
        margin-right: 5px;
      }
    }
  }
}

.logo-cls-1 {
  fill: #7d2ebc;
}

.logo-cls-1,
.logo-cls-2,
.logo-cls-3,
.logo-cls-4 {
  fill-rule: evenodd;
}

.logo-cls-2 {
  fill: #4dac3b;
}

.logo-cls-3 {
  fill: #4dac3b;
  filter: url(#gradient-overlay-1);
}

.logo-cls-4 {
  fill: #8e0dfe;
  filter: url(#gradient-overlay-2);
}

.mb--10 {
  margin-bottom: -10px !important;
}

// .badge.bg-danger {
//   color: #fff;
// }

// .pending-service-tasks-checklist-modal .badge {
//   width: 150px;;
//   border: none;
// }
/**/
.timeSlot-unit {
  max-height: 300px;
  overflow: auto;
}
.caldr-webapp .card {
  background-color: transparent;
  padding: 0;
  border: none;
  box-shadow: none;
}
.caldr-webapp .card .card-body {
  background-color: transparent !important;
  padding: 0;
  border: none;
}
.caldr-webapp .crm-main-details {
  margin-top: 0 !important;
}
.caldr-webapp .choose-set-offs {
  background-color: #fff;
  padding: 0;
  border: 0.063rem solid #eaeaea;
  border-radius: 10px;
}
.caldr-webapp .rdp-caption {
  justify-content: center;
  margin-bottom: 20px;
}
.caldr-webapp .rdp-cell {
  padding: 10px 0;
}
.caldr-webapp .rdp-cell .rdp-button {
  margin: 0 auto;
}
.caldr-webapp
  .choose-set-offs
  .rdp-month
  .rdp-table
  .rdp-tbody
  .rdp-row
  .my-selected:not([disabled]),
.caldr-webapp
  .choose-set-offs
  .rdp-month
  .rdp-table
  .rdp-tbody
  .rdp-row
  .rdp-day:hover:not([disabled]) {
  border-color: $success-color !important;
  background-color: $success-color !important;
}
.caldr-webapp .slot-foot-btns {
  margin-bottom: 0 !important;
}
.caldr-webapp .slot-foot-btns .btn {
  color: #fff;
  background-color: #491c96;
  border-color: #491c96;
  font-size: 1rem;
  font-weight: normal;
  padding: 6px 2rem;
}
.caldr-webapp .slot-foot-btns .btn-outline-secondary {
  color: #fff;
  background-color: #242424;
  border-color: #242424;
}
@media (max-width: 991px) {
  .caldr-webapp .choose-set-offs {
    margin-bottom: 20px;
  }
}
/**/
.job-amnt-table tr td {
  padding: 5px 15px;
  font-size: 0.95rem;
}
// .job-amnt-table tr.table-foot td {
//   border-top: 1px solid $default-color;
//   border-bottom: 1px solid $default-color;
//   font-size: 1.15rem;
//   font-weight: bold;
// }

.modified-pt-4 {
  padding-top: 1.6rem !important;
}

.modified-h-40 {
  height: 40px;
}

.edit-columns-modal .form-check-label,
.edit-columns-modal .list-group-item {
  text-transform: capitalize;
}

.text-capitalize-first::first-letter {
  text-transform: capitalize;
}

.iti--inline-dropdown {
  width: 100%;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #6350bb;
}

.fixed-job-footer-bar {
  width: 100%;
  position: fixed;
  bottom: 0;
  box-shadow: 1px 1px 1px 1px;
}
.text-length {
  position: absolute;
  top: 35px;
  right: 10px;
  opacity: 0.5;
  user-select: none;
  font-size: 14px;
}

.optional-label {
  font-size: 10px;
  padding: 3px;
  opacity: 0.8;
}
.form-select {
  height: 40px;
}
.panel-wrap {
  position: relative;
}
.same-ad-chk {
  position: absolute;
  top: 10px;
  left: 200px;
}

.condition-divider {
  position: relative;
  text-align: center;
  margin: 20px;
  div {
    border: 1px solid #ccc;
  }
  span {
    position: absolute;
    top: -18px;
    left: 38%;
    padding: 5px 20px;
    background-color: #fff;
    font-weight: 600;
    color: $primary-color;
    font-size: 20px;
  }
}
