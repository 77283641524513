@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
.customBtn {
    background: #4285F4;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgba(0, 0, 0 , 0.24) 0px 2px 2px 0px, rgba(0, 0, 0,  0.24) 0px 0px 1px 0px;
    padding: 0px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto, sans-serif";
    // height:40dp;
}

.custom-sign-in-btn{
    display: inline-flex;
    justify-content: left !important;
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.54);
    white-space: nowrap;
    width:200px;
    text-align: left;
    height:40px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto, sans-serif";
    padding:0px 8px;

}
.integrate-btn.btn {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    letter-spacing: 0.5px;
    border: 2px solid rgba(0, 0, 0, 0.34);
    border-radius: 3px;
    padding: 8px;
    line-height: 1;
    font-size: 14px;
    width: 255px;
    justify-content: flex-start;
    color: rgba(0, 0, 0, 0.54);



}
.integrate-btn.btn img {

    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
}

.integrate-mail-btn i {
    font-size: 20px;
    margin-right: 2px;
}
.integrate-btn.btn span{
    padding-left: 24px;
}