@import "../../../Assets/Styles/variables.scss";
.eb-popup-body {
  padding: 1.5rem;

  .eb-image-gallery {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      margin-left: -5px;
      margin-right: -5px;
      width: 100%;
      max-height: 400px;
      overflow-y: auto;
      box-shadow: 0 0 0;
      border: 0;
      li {
        flex: 0 0 20%;
        max-width: 20%;
        padding: 5px;
        position: relative;
        max-height:120px;

        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
          object-position: center;
          border: 1px solid $border-color;
        }

        .remove-thumbnail {
          background-color: $color-red;
          position: absolute;
          right: 7px;
          top: 4px;
          color: #fff;
          font-weight: 700;
          font-size: $text-size-xxs;
          padding: 0 2px;
          opacity: 0;
          visibility: hidden;
          transition: all ease-in-out 0.2s;
          i {
            display: block;
          }
        }

        &:hover {
          .remove-thumbnail {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  .btn {
    min-height: 36px;
    i {
      font-size: $text-size-sm;
    }
  }
}
