@import "../../../Assets/Styles/variables.scss";

.outline-element {
  outline: 1px dashed $primary-color;
  padding: 2px;
}

.responsive-screen-buttons {
  display: inline-block;
  .btn {
    padding: 0.2rem 0.7rem;
    margin: 0 5px;
    i {
      font-size: 1.3rem;
    }
  }
}

.email-builder-main {
  display: flex;

  .email-builder-sidebar {
    flex: 0 0 350px;
    max-width: 350px;
    background-color: $bg-light;
    padding: 1rem;
    min-height: calc(100vh - 64px);
    border-right: 1px solid $border-color;
    font-family: $font-montserrat;
    transition: all ease-in-out 0.3s;

    h4 {
      margin-bottom: 0;
      white-space: nowrap;
    }

    .structure {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        background-color: #cfcfcf;
        // box-shadow: 0 0 4px $border-color;
        padding: 5px;
        margin-bottom: 10px;
        width: 300px;
        z-index: 999;

        .row {
          margin-left: -5px;
          margin-right: -5px;

          > div {
            padding-left: 5px;
            padding-right: 5px;
          }
        }

        .structure-inner-col {
          font-weight: 500;
          text-align: center;
          background-color: #fff;
          border: 1px dashed $border-color;
          min-height: 42px;
        }
      }
    }
    .structure.widget-templates {
      list-style-type: none !important;

      li {
        background: transparent !important;
        border: 1px solid $border-color;
      }

    }

  }

  .email-builder-content-bar {
    padding: 2rem 1rem;
    background-color: #ededed;
    flex: 0 0 calc(100% - 350px);
    max-width: calc(100% - 300px);
    position: relative;
    transition: all ease-in-out 0.3s;
    height: calc(100vh - 66px);
    overflow: auto;
  }

  .email-sidebar-open {
    position: absolute;
    top: 1.5rem;
    left: 1rem;
    opacity: 0;
    visibility: hidden;
    transition: all ease-in-out 0.3s;
  }

  .inner-column {
    border: 1px dashed #ddd;
    padding: 10px;
    text-align: center;
  }
}

.hide-sidebar {
  overflow-x: hidden;

  .email-builder-sidebar {
    max-width: 0;
    padding: 1.5rem 0;
    overflow: hidden;
    opacity: 0;
  }

  .email-builder-content-bar {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .email-sidebar-open {
    opacity: 1;
    visibility: visible;
  }
}

.x-placeholder , li.x-placeholder{
  font-weight: 500;
  //text-align: center;
  background-color: #f8f8f8;
  border: 1px dashed $primary-color;
  min-height: 42px;
  max-height:50px;
  list-style: none;
  list-style-type: none;
  margin: 10px;
  width:97% !important;
}

.email-element-modal {
  .modal-dialog {
    width: 100%;
    max-width: 600px;

    .modal-content {
      border-radius: 0;
    }
  }

  .modal-header {
    background: #ededed;
  }
  .modal-body {
    > .row {
      margin-top: 20px;
    }
  }
  .element-inner-col {
    text-align: center;
    border: 1px solid $border-color;
    padding: 1rem;
    margin-bottom: 20px;
    border-radius: 10px;
    cursor: pointer;

    figure {
      height: 70px;
      line-height: 70px;
    }

    img {
      max-width: 60px;
    }

    h5 {
      margin-top: 0.8rem;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 767px) {
  .email-builder-main {
    flex-direction: column;
    .email-builder-sidebar {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .email-builder-content-bar {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .responsive-screen-buttons {
    display: block;
    text-align: left;
  }
}

.eb-popup {
  position: absolute;
  left: 50%;
  //bottom: calc(100% + 10px);
  transform: translate(-50%, -0%);
  max-width: 300px;
  box-shadow: 0 0 6px #0000001a;
  width: 300px;
  z-index: 3;
  background: #fff;
  border-radius: 8px;
  text-align: left;
  overflow: hidden;

  &.eb-popup-lg {
    max-width: 500px;
    width: 500px;
  }

  .form-group {
    .css-2b097c-container .css-yk16xz-control,
    .form-control {
      height: 38px;
      border-radius: 0 !important;
      background-color: #f9f9f9;
    }
  }

  .eb-popup-header {
    background-color: #f7f7f7;
    padding: 1rem 1.5rem;
    position: relative;

    h4 {
      margin-bottom: 0;
      font-size: $text-size-sm;
    }

    .eb-popup-close {
      position: absolute;
      right: 1.5rem;
      top: 13px;
      font-size: 1.2rem;
      font-weight: 900;
      color: #999;
      cursor: pointer;
    }
  }

  // Email Builder Modals

  .eb-popup-body {
    padding: 1.5rem;

    .eb-image-gallery {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        margin-left: -5px;
        margin-right: -5px;
        max-height: 400px;
        overflow-y: auto;
        box-shadow: 0 0 0;
        border: 0;
        li {
          flex: 0 0 20%;
          max-width: 20%;
          padding: 5px;
          position: relative;

          img {
            width: 100%;
            height: 60px;
            object-fit: scale-down;
            object-position: center;
            border: 1px solid $border-color;
          }

          .remove-thumbnail {
            background-color: $color-red;
            position: absolute;
            right: 7px;
            top: 7px;
            color: #fff;
            font-weight: 700;
            font-size: $text-size-xxs;
            padding: 0 2px;
            opacity: 0;
            visibility: hidden;
            transition: all ease-in-out 0.2s;
            i {
              display: block;
            }
          }

          &:hover {
            .remove-thumbnail {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }

    .btn {
      min-height: 36px;
      i {
        font-size: $text-size-sm;
      }
    }
  }

  .eb-add-border {
    .color-picker {
      text-align: center;
      border: 1px solid $border-color;
      min-width: 40px;
      height: 38px;
      line-height: 38px;
      background: #f9f9f9;

      svg {
        width: 18px;
      }
    }

    .add-border-width {
      display: flex;
      max-width: 150px;

      .form-control {
        text-align: center;
      }

      span {
        flex: 0 0 40px;
        background-color: $success-color;
        text-align: center;
        color: #fff;
        font-size: 1.5rem;
        line-height: 38px;

        i {
          display: block;
        }
      }
    }
  }
  .eb-adjust-image-size {
    .image-custom-size {
      position: relative;
      padding-left: 40px;
      padding-top: 1rem;
      span {
        width: 26px;
        height: 26px;
        line-height: 26px;
        border: 1px solid $border-color;
        background-color: #f9f9f9;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-0%, -50%);
        text-align: center;

        &.active {
          background-color: $success-color;
          color: #fff;
        }
      }
      .form-group {
        display: flex;
        align-items: center;

        .form-label {
          flex: 0 0 90px;
        }
      }
    }
  }

  .resize-slider {
    width: 100%;
    height: 12px;
    background-color: $border-color;
    border-radius: 30px;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .slider-inner {
      width: 80%;
      background-color: $success-color;
      height: 12px;
      border-radius: 30px;
      position: relative;

      span {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-0%, -50%);
        width: 30px;
        height: 30px;
        border: 9px solid $success-color;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 5px #ddd;
      }
    }
  }
  .eb-add-margin {
    .eb-add-margin-inner {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 19px;
        right: 30px;
        bottom: 19px;
        left: 30px;
        border: 1px dashed $border-color;
        z-index: -1;
      }

      .form-group {
        max-width: 60px;
        display: block;
        margin: auto;

        &.ed-spacing-right,
        &.ed-spacing-left {
          position: absolute;
          top: 50%;
          transform: translate(0%, -50%);
        }

        &.ed-spacing-right {
          right: 0;
        }
        &.ed-spacing-left {
          left: 0;
          margin: 0;
        }

        &.ed-spacing-bottom {
          margin-top: 90px;
        }

        .form-control {
          text-align: center;
        }
      }

      .auto-adjust-btn {
        width: 26px;
        height: 26px;
        line-height: 26px;
        border: 1px solid $border-color;
        background-color: #f9f9f9;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        &.active {
          background-color: $success-color;
          color: #fff;
        }
      }
    }
  }

  .eb-mail-merge-inner {
    min-height: 100px;
    .dropdown-list {
      li {
        &:hover {
          background-color: $primary-color;
          color: #fff;
        }
      }
    }
  }

  // Range Slider Custom Style
  input[type="range"] {
    -webkit-appearance: none;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: $success-color;
    border: none;
    border-radius: 10px;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: #fff;
    border: 6px solid $success-color;
    margin-top: -7px;
    box-shadow: 0 0 5px #999999;
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: $success-color;
  }
}

.eb-control-bar {
  position: absolute;
  top: 37%;
  left: 24%;
  z-index: 2;
  padding: 0.5rem 0;
  border-radius: 4px;
  box-shadow: 0 0 5px #0000001a;
  background-color: #fff;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    li {
      padding: 0rem 1rem;
      font-size: 1.3rem;
    }
  }
}

#templatePreview {
  display: block;
  margin: 0 auto;
}

.desktop,
.mobile-responsive {
  position: relative;
  height: 500px;
  border: 0px;
  margin: 0 auto,
}

.mobile-responsive .column-xs {
  max-width: 100% !important;
  width: 100% !important;
  margin-bottom: 20px;
}

#template.email-builder {
  list-style: none;
  margin: 0;
  padding: 0;

  li.ui-state-highlight {
    background-color: #cfcfcf;
    padding: 5px;
    margin-bottom: 10px;
    width: 300px !important;
    height: 52px !important;
    z-index: 999;
    cursor: grabbing !important;
    .row {
      margin-left: -5px;
      margin-right: -5px;

      >div {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .structure-inner-col {
      font-weight: 500;
      text-align: center;
      background-color: #fff;
      border: 1px dashed $border-color;
      min-height: 42px;
    }
  }
  .ed-row-handle{
    position: absolute;
    width:20px;
    height:20px;
    margin-top:-8px;
    border: 1px solid $border-color;
    z-index: 9999;
    display:none;
    font-weight: bold;
    padding: 0 1px;
    padding-top:2px ;
    font-size:1rem;
    background-color: $primary-color;
    color:#fff;
    // line-height: 1rem;
    cursor:grab;
    border-radius: 4px;
  }
  .ed-elem-container:hover .ed-row-handle{
    display:block;
  }

  .ed-container-active .ed-row-handle{
    display: none !important;
  }
}