.toolbar-button svg {
    margin: 0;
}

.toolbar-button {
    margin: 0;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.toolbar-container {
    width: 100%;
    outline: 1px solid rgb(191 189 204); 
    padding: 2px; 
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
