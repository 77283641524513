.react-datepicker-popper {
  z-index: 99;
}
.react-datepicker-wrapper {
  /* width: calc(100% - 40px); */
  display: block;
}
.react-datepicker-wrapper input {
  width: 100%;
  border: 0.063rem solid #e3e3e3;
  /* height: 38px; */
  height: 2.3rem;
  padding-left: 10px;
}
.custom-date-input:focus {
  border: 0.01rem solid #6e5dc6;
  outline: 0.01rem solid #6e5dc6;
}
