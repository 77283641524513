@import "../../../Assets/Styles/variables.scss";
.controlBar {
  margin-bottom: 10px;

  top: 0;
  left: 20px;
  right: 20px;
  z-index: 10;
}
/** Sidebar open **/
.flexible {
  position: fixed;
  z-index: 9999;
  left: 28%;

  top: 4rem;
}
/** Sidebar Closed **/
.hide-sidebar {
  .flexible {
    position: fixed;
    z-index: 9999;
    left: 1%;
    top: 10%;
  }
}
/****/
.scrolled {
  position: fixed;
}

.controlBar-left {
  left: 12%;
}

.controlBar-center {
  left: 45%;
}
/**OTHERS**/
.border-outline {
  outline-style: dashed !important;
  outline-color: $success-color !important;
  outline-width: 2px !important;
  outline-offset: -2px;
}

.ed-elem-sub-container.selected-element-outline {
  outline-style: solid !important;
  outline-color: $success-color !important;
  outline-width: 2px !important;
  outline-offset: -2px;
}

/** CONTAINER OUTLINE **/
.ed-elem-container.border-outline {
  outline-style: dashed !important;
  outline-color: $warning-color !important;
  outline-width: 2px !important;
  outline-offset: -2px;
}
.ed-elem-container.selected-element-outline {
  outline-style: solid !important;
  outline-color: $warning-color !important;
  outline-width: 2px !important;
  outline-offset: -2px;
}
/** COLUMN OUTLINE **/
.ed-elem-column.border-outline {
  outline-style: dashed !important;
  outline-color: $primary-color !important;
  outline-width: 2px !important;
  outline-offset: -2px;
}
.ed-elem-column.selected-element-outline {
  outline-style: solid !important;
  outline-color: $primary-color !important;
  outline-width: 2px !important;
  outline-offset: -2px;
}

.border-outline-box {
  outline-style: solid !important;
  outline-color: #007bff !important;
  outline-width: 2px !important;
  outline-offset: -2px;
  background-color: #eee;
}

.border-outline-box-hover {
  outline-style: dashed !important;
  outline-color: $success-color !important;
  outline-width: 2px !important;
  outline-offset: -2px;
}

.controlBar {
  width: auto;
  padding: 0;
  border-radius: 3px;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.controlBarInner {
  display: inline-flex;
  flex-direction: column;
}
.controlBarInner .editor-tab {
  display: inline-flex;
}

.controlBarInner .editor-tab .editor-tab-title {
  display: inline-block;
  background-color: #fff;
  padding: 8px 15px;
  font-weight: 600;
  border-radius: 4px 4px 0 0;
}
.controlBar ul {
  margin: 0px;
  padding: 0px;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-flex;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
  border: 1px solid $border-color;
  background-color: #fff;
  max-width: 500px;
  height: 44px;
}
.controlBar ul li {
  margin: 0;
  padding: 0px;
  padding-left: 5px;
  list-style-type: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  .font-size-control {
    display: inline-flex;
    height: 100%;
    border: 1px solid #e3e3e3;
    .font-size-minus,
    .font-size-plus {
      width: 34px;
      height: 36px;
      font-size: 20px;
      text-align: center;
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
    input {
      border: none;
      height: 100%;
      text-align: center;
    }
  }
  .btn-group {
    height: 100%;
  }
  button {
    background-color: #fff;
    height: 100%;
    width: 42px;
    height: 42px;
    border: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    border-radius: 0;
    i {
      font-size: 1.35rem;
    }
    &.bold {
      font-weight: bold;
    }
    &.italic {
      font-style: italic;
    }
    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-left: none;
    }
    &:focus {
      outline: none;
    }
  }
  .dropdown-menu {
    padding: 0;
    button {
      width: 100%;
    }
  }
  .text-align-action {
    .dropdown-menu {
      min-width: inherit;
      .dropdown-item {
        font-size: 1.5rem;
        padding: 0px 15px;
      }
    }
  }
  .font-size-action {
    .dropdown-menu {
      min-width: inherit;
    }
  }
}
.controlBar > button,
.controlBar select,
.controlBar input {
  height: 30px;
  /*padding: 0px !important;*/
  margin: 0px 1px;
  background: #fff;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.editor-alignment-active {
  color: $primary-color;
}

.editor-margin-box.editor-margin-box {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: button;
    margin: 0;
  }

  input[type="number"] {
    appearance: button;
    -webkit-appearance: button;
    -moz-appearance: textfield;
  }
}

ul.dropdown-list {
  width: auto;
  height: 122px;
  margin: 0px;
  padding: 0px;
  list-style: none;
  border: 1px solid #ccc;
  overflow-y: auto;
  font-size: 12px;
  border-radius: 3px;
  background: #fff;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block !important;
}

.dropdown-list li.active {
  background: #2196f3;
}

.controlBar ul.dropdown-list li {
  clear: both;
  padding: 1px 4px;
  margin: 0px;
  width: 100%;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.controlBar ul.dropdown-list li:hover {
  cursor: pointer;
  background: #ccc;
}

.ed-popover {
  position: absolute;
  z-index: 3;
  top: 100%;
  left: 0;
  background: transparent;
  width: 100%;
  box-shadow: "rgb(0 0 0 / 37%) 0px 3px 10px";
  padding: 20px 0;
}
.ed-cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  // background: #2196f3;
  z-index: 1;
}
.x-color-picker {
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  .chrome-picker {
    box-shadow: none !important;
  }
  .color-reset-btn {
    position: relative;
    z-index: 3;
  }
}
#tooltip-disabled {
  z-index: 99999;
}

.zIndex {
  position: absolute !important;
  z-index: 99999 !important;
}
.ed-elem-text {
  word-break: break-all;
}
