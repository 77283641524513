@import "../../../../../Assets/Styles/variables.scss";
.sms-box {
  border: 1px solid $primary-color;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  &.active {
    background-color: $success-color;
    border: 1px solid $primary-color;
    color: #fff;
  }
  &:hover {
    background-color: $success-color;
    border: 1px solid $primary-color;
    color: #fff;
  }
}
