/* src/index.css or App.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Open+Sans&display=swap');

/* Existing styles */

/* styles.css */
.toolbar-icon {
    margin-right: 5px;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .toolbar-icon:hover {
    transform: scale(1.1);
  }
  
  .font-selector {
    margin-right: 10px;
    cursor: pointer;
  }
  
  .font-selector:hover {
    background-color: #f0f0f0;
  }
  
  .color-picker-icon {
    margin-right: 5px;
    cursor: pointer;
  }
  
  /* styles.css */
.color-picker-wrapper {
  position: relative;
  width: 36px;
  height: 36px;
}

.color-picker-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 36px;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.color-picker-icon {
  font-size: 24px;
  cursor: pointer;
}
.color-picker-icon:hover {
    opacity: 0.8;
  }
  

  .toolbar-group {
    display: flex;
    align-items: center;
  }
  
  .toolbar-separator {
    width: 1px;
    height: 24px;
    background-color: #ccc;
    margin: 0 8px;
  }

  .mailMergeGroupStyle {
    width: 100%;
    justify-content: center;
    border-top: 1px solid #d2cbcb;
    margin-top: 5px;
    padding-top: 2px;
  }
  .mailMergeGroupStyle .toolbar-button {
    font-weight: normal;
  }
