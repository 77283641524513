@import "../../../Assets/Styles/variables.scss";

.business-flow-builder {
  position: relative;
  display: flex;
  justify-content: center;
  // align-items: center;
  height: 100vh;

  .choose-options {
    width: 800px;
    border: 1px solid #ccc;
    margin: 20px auto;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

    .col {
      position: relative;
      border: 1px solid #ccc;
      border-radius: 5px;
      width: 220px;
      height: 120px;
      margin: 10px;
      padding: 0 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      .label {
        text-align: center;
        font-weight: bold;
      }
      .check-button {
        position: absolute;
        right: 0px;
        bottom: 0px;
      }
      .hint-icon {
        position: absolute;
        right: 5px;
        top: 0px;
      }
    }
  }
  .input-group {
    flex-wrap: nowrap;
  }
  .start-business-flow {
    width: 100%;
    padding-top: 20px;
    .stepper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin: 20px auto;
      width: 80%;
    }
    .stepper::before {
      content: "";
      position: absolute;
      top: 15px;
      left: 8%; /* Adjust for no extra line at start */
      right: 5%; /* Adjust for no extra line at end */
      height: 2px;
      background-color: #e0e0e0;
      z-index: 1;
    }
    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: relative;
      z-index: 2;
      &.active {
        .step-circle {
          background-color: $primary-color;
          border-color: $primary-color;
        }
        .step-circle::after {
          content: "";
          position: absolute;
          top: 8px;
          left: 8px;
          width: 10px;
          height: 10px;
          background-color: white;
          border-radius: 50%;
        }
        .step-title {
          color: $primary-color;
        }
      }
      &.completed {
        .step-circle {
          border-color: $success-color;
          background-color: $success-color;
        }
        .step-circle::after {
          content: "";
          position: absolute;
          top: 8px;
          left: 8px;
          width: 10px;
          height: 10px;
          background-color: white;
          border-radius: 50%;
        }
        .step-title {
          color: $success-color;
        }
      }
    }
    .step-circle {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: gray;
      border: 2px solid #d3d3d3;
      position: relative;
    }

    .step-title {
      margin-top: 8px;
      // color: $primary-color;
      font-weight: bold;
    }

    .left-side {
      .numberWidth {
        width: 60px !important;
      }
      .action-list-box {
        text-align: center;
        border-radius: 5px;
        margin: 10px;
        padding: 5px;
        cursor: pointer;
        position: relative;
        color: #292929;
        box-shadow: 0px 0px 1px 1px #ccc;
        div {
          position: relative;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          font-size: 14px;
        }
        &:hover {
          border-color: $primary-color !important;
          color: $primary-color;
          box-shadow: 0px 0px 1px 1px $primary-color;
        }
        &.active {
          border-color: $primary-color !important;
          color: $primary-color;
        }
        .tooltip-position {
          text-align: right;
          display: block;
          color: $warning-color;
        }
      }
    }

    .right-side.sticky-right {
      position: fixed;
      top: 120px;
      right: 10px;
      z-index: 10;

      .step-wrapper {
        display: flex;
        flex-direction: column;
        align-items: start;
        position: relative;
      }

      .step-item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        position: relative;
      }

      .step-item .circle {
        width: 24px;
        height: 24px;
        border: 2px solid gray;
        border-radius: 50%;
        background-color: white;
        position: relative;
        z-index: 1;
      }

      .step-item.active {
        .circle {
          border-color: $primary-color;
          background-color: $primary-color;
        }
        .content {
          color: $primary-color;
        }
      }
      .step-item.completed {
        .circle {
          background-color: transparent;
          width: 28px;
          height: 28px;
          border: 1px solid $warning-color;
        }
        .content {
          color: $success-color;
        }
      }

      .step-item .content {
        margin-left: 15px;
        font-size: 16px;
        font-weight: bold;
      }

      .step-item:not(:last-child)::after {
        content: "";
        position: absolute;
        top: 25px;
        left: 12px;
        width: 2px;
        height: calc(100% - 25px);
        background-color: gray;
        z-index: 0;
      }

      .step-item.active:not(:last-child)::after {
        background-color: $primary-color;
      }
    }
  }
}
